import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container, Form, ProgressBar } from "react-bootstrap";
import { BsCalendarDate, BsBuildings } from "react-icons/bs";
import { IoCashOutline } from "react-icons/io5";
import { FiMapPin } from "react-icons/fi";
import { CiTimer } from "react-icons/ci";
import { GiBunkBeds } from "react-icons/gi";
import { PiOfficeChairBold } from "react-icons/pi";
import { germanStates } from "../../../DataFiles/countries";
import { roomStaticData } from "../../../DataFiles/data";
import { useSpring, animated } from "react-spring";
import { GiCash } from "react-icons/gi";
import { AiTwotoneEuroCircle } from "react-icons/ai";
import { ImSpinner9 } from "react-icons/im";
import { useSelector } from "react-redux";

export default function Room({
  t,
  createFormHandler,
  error,
  setError,
  loading,
}) {
  const { user } = useSelector((state) => state.userInfo);
  const [currentSection, setCurrentSection] = useState(0);
  const formRef = useRef(null);

  const [location, setLocation] = useState({
    state: null,
    city: null,
  });

  const [leaseDetails, setLeaseDetails] = useState({
    lengthOfLease: null,
    propertyType: null,
    numberOfBedrooms: null,
    serviceFee: null,
  });

  const [pricing, setPricing] = useState({
    rent: null,
    securityDeposit: null,
  });

  const [moveIn, setMoveIn] = useState({
    moveInDate: null,
    utilities: "Yes",
    roomType: null,
  });

  const [details, setDetails] = useState({
    detail: null,
  });
  const [animatedElements, setAnimatedElements] = useState(false);

  useEffect(() => {
    if (user?.preferredState) {
      setLocation((prev) => ({ ...prev, state: user.preferredState }));
    }
    console.log(user);
  }, [user]);

  const stateCities =
    germanStates.find((y) => y.sampleName === location.state)?.cities || [];

  const handleLocationChange = (field) => (e) => {
    setLocation({ ...location, [field]: e.target.value });
  };

  const handleLeaseDetailsChange = (field) => (e) => {
    setLeaseDetails({ ...leaseDetails, [field]: e.target.value });
  };

  const handlePricingChange = (field) => (e) => {
    setPricing({ ...pricing, [field]: e.target.value });
  };

  const handleMoveInChange = (field) => (e) => {
    setMoveIn({ ...moveIn, [field]: e.target.value });
  };

  const handleDetailsChange = (field) => (e) => {
    setDetails({ ...details, [field]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createFormHandler({
      ...location,
      ...leaseDetails,
      ...pricing,
      ...moveIn,
      ...details,
    });
  };

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const nextSection = () => {
    setAnimatedElements(true);
    if (currentSection < 4) {
      setCurrentSection(currentSection + 1);
      scrollToForm();
    }
    setTimeout(() => setAnimatedElements(false), 300);
  };

  const previousSection = () => {
    setAnimatedElements(true);
    setError("");
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      scrollToForm();
    }
    setTimeout(() => setAnimatedElements(false), 300);
  };

  const progressPercentage = ((currentSection + 1) / 5) * 100;

  const animationProps = useSpring({
    opacity: 1,
    transform: "translateX(0)",
    from: { opacity: 0, transform: "translateX(100px)" },
    reset: true,
    config: { duration: animatedElements ? 300 : 0 },
  });

  return (
    <Container
      ref={formRef}
      className="flex justify-center lg:mt-[190px] md:mt-[240px]"
      // className="flex justify-center lg:mt-[190px] md:mt-[240px]"
    >
      <Form
        onSubmit={submitHandler}
        className="indirect_align w-full lg:w-3/4 p-4 bg-light rounded shadow-sm"
      >
        <div
        // className="col-span-2 form_smallScreen_caption"
        >
          <h2 className="text-center">
            {t("inDirectServicePage.Create your")}{" "}
            {t("inDirectServicePage.Room")} {t("inDirectServicePage.request")}
          </h2>
          <p>
            {t("inDirectServicePage.Room description")}{" "}
            <a
              href="#service_description"
              className="text-[#4475b4] cursor-pointer"
            >
              {t("inDirectServicePage.know more")}
            </a>
          </p>
        </div>

        <ProgressBar
          animated
          variant="info"
          now={progressPercentage}
          className="col-span-2 mb-4"
        />

        <animated.div style={animationProps}>
          {currentSection === 0 && (
            <div>
              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <FiMapPin />
                <Form.Select
                  className="border-0 shadow-none"
                  value={location.state}
                  onChange={handleLocationChange("state")}
                  required
                >
                  {germanStates.map((e, i) => (
                    <option key={i} value={e.sampleName}>
                      {e.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded">
                <Form.Select
                  className="border-0 shadow-none"
                  value={location.city}
                  onChange={handleLocationChange("city")}
                  required
                >
                  <option hidden>
                    {t("inDirectServicePage.Select")}{" "}
                    {t("inDirectServicePage.City")}
                  </option>
                  {stateCities.map((city, i) => (
                    <option key={i} value={city}>
                      {city}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          )}

          {currentSection === 1 && (
            <div className="grid md:grid-cols-1 gap-2">
              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <CiTimer />
                <Form.Select
                  className="border-0 shadow-none"
                  value={leaseDetails.lengthOfLease}
                  onChange={handleLeaseDetailsChange("lengthOfLease")}
                  required
                >
                  <option hidden>
                    {t("inDirectServicePage.Length of lease")}
                  </option>
                  {roomStaticData.lengthOfLease.map((e, i) => (
                    <option key={i} value={e}>
                      {t(`inDirectServicePage.${e}`)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <BsBuildings />
                <Form.Select
                  className="border-0 shadow-none"
                  value={leaseDetails.propertyType}
                  onChange={handleLeaseDetailsChange("propertyType")}
                  required
                >
                  <option hidden>
                    {t("inDirectServicePage.Property Type")}
                  </option>
                  {roomStaticData.propertyType.map((e, i) => (
                    <option key={i} value={e}>
                      {t(`inDirectServicePage.${e}`)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <GiBunkBeds />
                <Form.Select
                  className="border-0 shadow-none"
                  value={leaseDetails.numberOfBedrooms}
                  onChange={handleLeaseDetailsChange("numberOfBedrooms")}
                  required
                >
                  <option hidden>
                    {t("inDirectServicePage.Number Of Bedrooms")}
                  </option>
                  {roomStaticData.number_of_Bedrooms.map((e, i) => (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          )}

          {currentSection === 2 && (
            <div className="grid md:grid-cols-2 gap-2">
              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <GiCash />
                <Form.Control
                  type="number"
                  className="border-0 shadow-none"
                  value={pricing.rent}
                  onChange={handlePricingChange("rent")}
                  placeholder={t("inDirectServicePage.What's Your Rent?")}
                  required
                />
              </Form.Group>

              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <AiTwotoneEuroCircle />
                <Form.Control
                  type="number"
                  className="border-0 shadow-none"
                  value={pricing.securityDeposit}
                  onChange={handlePricingChange("securityDeposit")}
                  placeholder={t(
                    "inDirectServicePage.What's Your Security Deposit"
                  )}
                  required
                />
              </Form.Group>

              <Form.Group className="col-span-2 flex bg-white items-center py-1 pl-2 rounded">
                <GiBunkBeds />
                <Form.Select
                  className="border-0 shadow-none"
                  value={leaseDetails.serviceFee}
                  onChange={handleLeaseDetailsChange("serviceFee")}
                  required
                >
                  <option hidden>{t("inDirectServicePage.Service fee")}</option>
                  {["None", "€50", "€100", "€200", "More than €250"].map(
                    (e, i) => (
                      <option key={i} value={e}>
                        {e === "€100"
                          ? `€100 (*${t("inDirectServicePage.recommended")}*)`
                          : t(`inDirectServicePage.${e}`)}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
            </div>
          )}

          {currentSection === 3 && (
            <>
              <Form.Group className="bg-[#eeeeee] border rounded mb-3">
                <Form.Label className="flex items-center w-[100%] rounded-top mr-3 bg-white p-2">
                  <BsCalendarDate className="text-[19px] mr-2" />
                  {t("inDirectServicePage.Move in date")}
                </Form.Label>
                <div className="p-2">
                  <Form.Control
                    type="date"
                    className="border-0 shadow-none"
                    value={moveIn.moveInDate}
                    onChange={handleMoveInChange("moveInDate")}
                    min={new Date().toISOString().split("T")[0]}
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-span-2 bg-[#eeeeee] border rounded mb-3">
                <Form.Label className="flex items-center w-[100%] rounded-top mr-3 bg-white p-2">
                  <PiOfficeChairBold className="text-[19px] mr-2" />
                  {t("inDirectServicePage.Room type")}
                </Form.Label>
                <div className="p-2">
                  {roomStaticData.roomType.map((type, i) => (
                    <Form.Check
                      key={i}
                      inline
                      id={`roomType${i}`}
                      name="roomType"
                      label={t(`inDirectServicePage.${type}`)}
                      value={type}
                      onChange={handleMoveInChange("roomType")}
                    />
                  ))}
                </div>
              </Form.Group>

              <Form.Group className="bg-[#eeeeee] border rounded">
                <Form.Label className="flex items-center w-[100%] rounded-top mr-3 bg-white p-2">
                  <IoCashOutline className="text-[21px] mr-2" />
                  {t(
                    "inDirectServicePage.Rent includes utilities like water, electricity, etc."
                  )}
                </Form.Label>
                <div className="p-2">
                  {["Yes", "No"].map((opt, i) => (
                    <Form.Check
                      key={i}
                      inline
                      id={`utilities${i}`}
                      name="utilities"
                      label={t(`inDirectServicePage.${opt}`)}
                      type="radio"
                      value={opt}
                      checked={moveIn.utilities === opt}
                      onChange={handleMoveInChange("utilities")}
                    />
                  ))}
                </div>
              </Form.Group>
            </>
          )}

          {currentSection === 4 && (
            <Form.Group className="col-span-2 bg-white items-center pl-2 rounded">
              <Form.Control
                style={{ minHeight: "120px" }}
                as="textarea"
                className="border-0 shadow-none"
                value={details.detail}
                onChange={handleDetailsChange("detail")}
                placeholder={t(
                  "inDirectServicePage.Enter your property overview (it helps to find the room easier.)"
                )}
              />
            </Form.Group>
          )}
        </animated.div>

        {/* Error Handling */}
        {error && (
          <Alert variant="danger" className="col-span-2 mt-3">
            {error}
          </Alert>
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-end mt-4">
          {currentSection > 0 && (
            <Button
              variant="secondary"
              className="mr-3"
              onClick={previousSection}
            >
              {t("inDirectServicePage.Previous")}
            </Button>
          )}
          {currentSection < 4 && (
            <Button variant="info" onClick={nextSection}>
              {t("inDirectServicePage.Next")}
            </Button>
          )}
          {currentSection === 4 && (
            <Button
              className="d-flex items-center"
              variant="info"
              type="submit"
              disabled={loading}
            >
              {loading && <ImSpinner9 className="animate-spin mr-2" />}
              {t("inDirectServicePage.Submit")}
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
}
