import React, { useRef } from "react";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";

import "./HomeScreen.scss";
import SEO from "../../utils/SEO";
import seoData from "../../DataFiles/seoData";
import GlobalChat from "./components/GlobalChat";
import { useScrollPosition } from "../../hooks/useScrollPosition";

export default function HomeScreen() {
  const { t } = useTranslation();
  const scrollPosition = useScrollPosition();
  const homeContainer = useRef(null);

  const seo = seoData.HomeScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />
      <div ref={homeContainer}>
        <Navigation />
        <Header t={t} />

        <div>
          <GlobalChat
            t={t}
            scrollPosition={scrollPosition}
            homeContainer={homeContainer}
          />
        </div>
        <FooterTop top="top" bottom="bottom" />
      </div>
    </>
  );
}
