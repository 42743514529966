import React from "react";
import { ImCancelCircle } from "react-icons/im";
import { MdOutlinePayments } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { PiWarningOctagonBold } from "react-icons/pi";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { inDirectCategories } from "../../DataFiles/data";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { FaMoneyBillWave } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

export default function RoomInterest({
  submit,
  interestView,
  handleInterestView,
  handleAcceptInterest,
  handleRejectInterest,
  accepted,
  interestError,
}) {
  const { t } = useTranslation();

  return (
    <>
      {interestView && (
        <div
          className="popup-view flex items-center justify-center min-h-screen fixed top-0 left-0 w-full z-50"
          style={{
            background: "linear-gradient(to bottom, #00000080, #FFFFFF80)",
          }}
        >
          <div
            className="popView p-6 max-w-lg rounded-lg shadow-lg relative"
            style={{
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="flex items-center mb-4">
              <img
                className="w-[60px] h-[60px] rounded-full shadow-sm"
                src={inDirectCategories.Room.smallicon3}
                alt="Room Icon"
                aria-hidden="true"
              />
              <h2 className="ml-4 font-bold text-xl text-gray-800">
                {t("interestComponent.Room")}{" "}
                {t("interestComponent.Interest Details")}
              </h2>
            </div>
            <div className="mx-3 pb-6">
              <div
                className="flex items-center py-4 px-5 rounded-lg shadow-sm mt-6"
                style={{ backgroundColor: "#F3F4F6" }}
              >
                <RiMoneyEuroCircleLine
                  className="mr-3 text-blue-600"
                  size={28}
                />
                <div>
                  <span className="block text-sm font-bold text-gray-600">
                    {t("interestComponent.Rent")}:
                  </span>
                  <span className="text-lg text-gray-800 font-semibold">
                    {interestView.rent_response}
                  </span>
                </div>
              </div>
              <div
                className="flex items-center py-4 px-5 rounded-lg shadow-sm mt-6"
                style={{ backgroundColor: "#F3F4F6" }}
              >
                <FaMoneyBillWave className="mr-3 text-green-600" size={28} />
                <div>
                  <span className="block text-sm font-bold text-gray-600">
                    {t("interestComponent.Security deposit")}:
                  </span>
                  <span className="text-lg text-gray-800 font-semibold">
                    {interestView.securityDeposit_response}
                  </span>
                </div>
              </div>
              <div
                className="flex items-center py-4 px-5 rounded-lg shadow-sm mt-6"
                style={{ backgroundColor: "#F3F4F6" }}
              >
                <MdOutlinePayments className="mr-3 text-purple-600" size={28} />
                <div>
                  <span className="block text-sm font-bold text-gray-600">
                    {t("interestComponent.Available from")}:
                  </span>
                  <span className="text-lg text-gray-800 font-semibold">
                    {new Date(interestView.available_from).toLocaleDateString(
                      "en-GB",
                      {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                  </span>
                </div>
              </div>
              <div
                className="flex items-center py-4 px-5 rounded-lg shadow-sm mt-6"
                style={{ backgroundColor: "#F3F4F6" }}
              >
                <MdOutlinePayments className="mr-3 text-yellow-600" size={28} />
                <div>
                  <span className="block text-sm font-bold text-gray-600">
                    {t("interestComponent.Service fee")}:
                  </span>
                  <span className="text-lg text-gray-800 font-semibold">
                    {interestView.serviceFee_response}
                  </span>
                </div>
              </div>
              <div
                className="flex py-4 px-5 rounded-lg shadow-sm mt-6"
                style={{ backgroundColor: "#F3F4F6" }}
              >
                <span className="block text-sm font-bold text-gray-600 mr-3">
                  {t("interestComponent.Details")}:
                </span>
                <span className="text-gray-700 text-base break-words">
                  {interestView.details_response}
                </span>
              </div>
              {interestError && (
                <div
                  className="col-span-2 text-red-600 mt-6 flex items-center p-4 rounded-lg"
                  style={{
                    backgroundColor: "#FEE2E2",
                  }}
                  role="alert"
                >
                  <PiWarningOctagonBold className="mr-3 text-2xl" />
                  <span>{interestError}</span>
                </div>
              )}
              {submit && !accepted && (
                <div className="flex justify-center gap-6 mt-8">
                  <Button
                    variant="success"
                    onClick={() =>
                      submit(
                        t("interestComponent.Accept Interest"),
                        t(
                          "interestComponent.are you sure to accept this interest"
                        ),
                        t("interestComponent.Yes, I do"),
                        t("interestComponent.No, I do'nt"),
                        handleAcceptInterest
                      )
                    }
                    className="d-flex items-center justify-center py-3 w-1/3 text-white hover:scale-105 transition-transform"
                    style={{
                      backgroundColor: "#10B981",
                      borderColor: "transparent",
                    }}
                  >
                    <TiTick className="mr-2" /> {t("interestComponent.Accept")}
                  </Button>
                  <Button
                    onClick={() =>
                      submit(
                        t("interestComponent.Reject Interest"),
                        t(
                          "interestComponent.are you sure to accept this interest"
                        ),
                        t("interestComponent.Yes, I do"),
                        t("interestComponent.No, I do'nt"),
                        handleRejectInterest
                      )
                    }
                    className="d-flex items-center justify-center py-3 w-1/3 text-white hover:scale-105 transition-transform"
                    style={{
                      backgroundColor: "#6B7280",
                      borderColor: "transparent",
                    }}
                  >
                    <AiOutlineDelete className="mr-2" />
                    {t("interestComponent.Delete")}
                  </Button>
                </div>
              )}
            </div>
            <button
              onClick={() => handleInterestView("")}
              className="absolute top-4 right-4 hover:scale-105 transition-transform"
            >
              <ImCancelCircle className="text-3xl text-gray-700" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
