import React, { useState, useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import "../components.scss"; // Add your CSS styles here

const CustomCookieConsent = () => {
  const [showModal, setShowModal] = useState(false); // Modal state
  const [preferences, setPreferences] = useState({
    essential: true, // Essential cookies are always enabled
    analytics: false,
    marketing: false,
  });

  // Add GA4, GTM, and Hotjar scripts conditionally
  useEffect(() => {
    if (preferences.analytics) {
      // Google Analytics 4 (GA4)
      const scriptGA = document.createElement("script");
      scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=YOUR_GA4_ID`;
      scriptGA.async = true;
      document.head.appendChild(scriptGA);

      scriptGA.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "YOUR_GA4_ID"); // Replace with your GA4 ID
      };

      // Google Tag Manager (via GTM ID)
      const scriptGTM = document.createElement("script");
      scriptGTM.src = `https://www.googletagmanager.com/gtm.js?id=YOUR_GTM_ID`;
      scriptGTM.async = true;
      document.head.appendChild(scriptGTM);
    }

    if (preferences.marketing) {
      // Hotjar (example: your Hotjar site ID)
      const scriptHotjar = document.createElement("script");
      scriptHotjar.src = `https://static.hotjar.com/c/hotjar-YOUR_HOTJAR_ID.js?sv=6`;
      scriptHotjar.async = true;
      document.head.appendChild(scriptHotjar);
    }
  }, [preferences]);

  const handleAcceptAll = () => {
    setPreferences({
      essential: true,
      analytics: true,
      marketing: true,
    });
    Cookies.set(
      "preferences",
      JSON.stringify({
        essential: true,
        analytics: true,
        marketing: true,
      }),
      { expires: 365 }
    );
  };

  const handleSavePreferences = () => {
    Cookies.set("preferences", JSON.stringify(preferences), { expires: 365 });
    Cookies.set("CookieConsent", true, { expires: 365 });

    // Hide cookie consent section (simulate onAccept behavior)
    const consentElement = document.querySelector(".CookieConsent");
    if (consentElement) {
      consentElement.style.display = "none";
    }
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept All"
        enableDeclineButton
        declineButtonText="Decline All"
        onAccept={handleAcceptAll}
        buttonStyle={{
          backgroundColor: "#4CAF50",
          color: "#fff",
          padding: "10px 10px",
          borderRadius: "10px",
        }}
        declineButtonStyle={{
          backgroundColor: "",
          padding: "10px 10px",
        }}
      >
        <div className="">
          <h4>We Value Your Privacy</h4>
          <p>
            We use cookies to enhance your experience. Some are essential, while
            others are used for analytics and marketing purposes. You can
            customize your preferences.
          </p>
          <button
            onClick={() => setShowModal(true)}
            className="bg-[#4681f0] p-2 rounded"
          >
            Customize Preferences
          </button>
        </div>
      </CookieConsent>

      {/* Modal for Custom Preferences */}
      {showModal && (
        <div className="cookie-modal font-sans">
          <div className="cookie-modal-content px-3 py-5">
            <h4 className="">Customize Your Cookie Preferences</h4>
            <p>
              Adjust your preferences for how we use cookies on this site.
              Essential cookies are always enabled.
            </p>
            <div className="cookie-category">
              <h5>Essential Cookies</h5>
              <p>
                These cookies are necessary for the website to function and
                cannot be disabled.
              </p>
              <label>
                <input className="mr-2" type="checkbox" checked disabled />
                Enabled
              </label>
            </div>
            <div className="cookie-category">
              <h5>Analytics Cookies</h5>
              <p>
                These cookies help us understand how our website is used and
                improve user experience.
              </p>
              <label>
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      analytics: e.target.checked,
                    })
                  }
                />
                Enable Analytics Cookies (GA4)
              </label>
            </div>
            <div className="cookie-category">
              <h5>Marketing Cookies</h5>
              <p>
                These cookies are used to deliver personalized marketing
                content.
              </p>
              <label>
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={preferences.marketing}
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      marketing: e.target.checked,
                    })
                  }
                />
                Enable Marketing Cookies (Hotjar)
              </label>
            </div>
            <div className="modal-actions">
              <button
                onClick={handleSavePreferences}
                style={{
                  backgroundColor: "#4CAF50",
                  color: "#fff",
                  padding: "10px 20px",
                }}
                className="rounded"
              >
                Accept Selected
              </button>
              <button
                onClick={closeModal}
                style={{
                  backgroundColor: "#f44336",
                  color: "#fff",
                  padding: "10px 20px",
                  marginLeft: "10px",
                }}
                className="rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomCookieConsent;
