import { useEffect } from "react";
import { toast } from "react-toastify";

export const useLoginHandler = ({
  t,
  setError,
  apiUrl,
  dispatch,
  loginUser,
  file,
  setFile,
  changePasswordMailSend,
  updateProfile,
}) => {
  //functions
  const loginHandler = (e) => {
    e.preventDefault();
    setError("");
    dispatch(loginUser(file));
  };

  const handleChange = ({ currentTarget: input }) => {
    setFile({ ...file, [input.name]: input.value });
  };

  const handleForgetPassword = () => {
    setError("");
    if (!file.email.includes(".com") && !file.email.includes("@")) {
      return setError(t("LI To change password, Enter valid mail id"));
    }
    dispatch(changePasswordMailSend(file.email));
  };

  const googleAuth = () => {
    window.open(`${apiUrl}/google/callback`, "_self");
  };
  return { loginHandler, handleChange, handleForgetPassword, googleAuth };
};

export const useEffectLogin = ({
  location,
  dispatch,
  loadUser,
  user,
  navigate,
  updateProfile,
  file,
  setError,
}) => {
  useEffect(() => {
    if (location.pathname == "/login") {
      dispatch(loadUser());
    }
  }, []);

  useEffect(() => {
    if (location.pathname == "/login" && user.isAuthenticated) navigate("/");
  }, [user.isAuthenticated]);

  useEffect(() => {
    updateProfile.message == "Password reset email sent successfully" &&
      toast.success(updateProfile.message);
  }, [updateProfile.message]);

  useEffect(() => {
    setError(updateProfile.error);
  }, [updateProfile.error]);

  useEffect(() => {
    setError(user.error);
  }, [user]);
  return;
};
