import React, { useState, useMemo } from "react";
import "./directServicePage.scss";
import FirstHead from "./components/FirstHead";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginEachPage from "../../components/samplePage/LoginEachPage";
import {
  createDService,
  deleteDService,
  getDService,
} from "../../store/Actions/DirectService";
import Status from "./components/Status";
import Loading from "../../components/samplePage/Loading";
import { directCategories } from "../../DataFiles/data";
import { loadUser } from "../../store/Actions/User";
import { useTranslation } from "react-i18next";
import { useEffectDService } from "./hooks/useEffectDService";
import { useDServiceFunctions } from "./hooks/useDServiceFunctions";
import RoomVerification from "./forms/RoomVerification";
import FooterTop from "../../components/common/FooterTop";
import SEO from "../../utils/SEO";
import seoData from "../../DataFiles/seoData";

// Custom hook for handling authentication and loading state
const useAuthRedirect = (userInfo, user, LoadingComponent) => {
  if (userInfo.loading) {
    return <LoadingComponent type="servicePage" color="#36d7b7" size={120} />;
  }
  if (!user.isAuthenticated) {
    return <LoginEachPage />;
  }
  return null;
};

export default function DirectServicePage() {
  const { item } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Memoize the selected values to avoid unnecessary rerenders
  const userInfo = useSelector((state) => state.userInfo);
  const user = useSelector((state) => state.user);
  const dService = useSelector((state) => state.dService);
  const myDService = useSelector((state) => state.myDService);

  const query = new URLSearchParams(location.search);
  const step = useMemo(
    () => (query.get("step") === "status" ? "status" : null),
    [query]
  );
  const categoryData = useMemo(
    () => directCategories.find((e) => e.name === item),
    [item]
  );

  const [services, setServices] = useState({});
  const [error, setError] = useState("");
  const [savedUrl, setSavedUrl] = useState(null);

  const { formSubmit, handleRemove, handleNext } = useDServiceFunctions({
    setError,
    dispatch,
    createDService,
    deleteDService,
    item,
    services,
    dService,
    directCategories,
  });

  useEffectDService({
    dispatch,
    setError,
    loadUser,
    getDService,
    item,
    myDService,
    setSavedUrl,
    step,
    setServices,
    dService,
    services,
    location,
    savedUrl,
    navigate,
  });

  const seo = seoData.DirectServicePage;

  // Handle authentication and loading early return
  const authRedirect = useAuthRedirect(userInfo, user, Loading);
  if (authRedirect) return authRedirect;

  // Helper function to render the service form
  const renderServiceForm = () => {
    if (step === "status") {
      return (
        <Status
          t={t}
          item={item}
          handleRemove={handleRemove}
          error={error}
          loading={dService.loading}
        />
      );
    }

    switch (item) {
      case "Room verification":
        return (
          <RoomVerification
            t={t}
            error={error}
            setError={setError}
            formSubmit={formSubmit}
            loading={dService.loading}
          />
        );
      default:
        return <p>{t("directServicePage.Service Not Available")}</p>;
    }
  };

  return (
    <>
      <SEO
        title={`${item} Services | Stayforall`}
        description={`Stayforall provides direct services like ${item} to make your move to Germany stress-free.`}
        keywords={`${item}, direct services, Stayforall`}
        ogTitle={`${item} Services | Stayforall`}
        ogDescription={`Explore Stayforall's direct services including ${item} for students in Germany.`}
        ogImage={seo.ogImage}
        ogUrl={`https://stayforall.com/directServicePage/${item}`}
        twitterTitle={`${item} Services | Stayforall`}
        twitterDescription={`Stayforall offers direct services like ${item} in Germany.`}
        twitterImage={seo.twitterImage}
        canonicalUrl={`https://stayforall.com/directServicePage/${item}`}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={{
          "@context": "http://schema.org",
          "@type": "Product",
          name: `${item} Services | Stayforall`,
          image: "/images/logo/SFAlargeLogo.webp",
          description: `Direct services from Stayforall to ensure a smooth transition to Germany, including ${item}.`,
          brand: {
            "@type": "Brand",
            name: "Stayforall",
          },
        }}
      />

      <div className="directmain font-sans pb-5">
        <div className="backgdirect"></div>
        <FirstHead
          t={t}
          item={item}
          categoryData={categoryData}
          handleNext={handleNext}
        />
        {myDService.loading ? (
          <Loading type="hoverLoading" color="#36d7b7" size={120} />
        ) : (
          renderServiceForm()
        )}
        <div className="border-b mt-[160px]"></div>
        <FooterTop bottom="bottom" bgColor="" />
      </div>
    </>
  );
}
