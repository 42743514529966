import { useEffect } from "react";

export const useEffectGlobalChat = ({
  dispatch,
  loadUser,
  setUserInfo,
  user,
  userInfo,
  setState,
  state,
  getGlobalMessages,
  globalMessage,
  setError,
  setConversations,
  socketRef,
  io,
  socketUrl,
  chatContainerRef,
  setIsAtBottom,
  hasMounted,
  isAtBottom,
  lastMessageRef,
  conversations,
}) => {
  // dispatch loadUser
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  useEffect(() => {
    if (userInfo?.preferredState) {
      setState(userInfo.preferredState);
    }
    // else {
    //   setState("Baden-Württemberg");
    // }
  }, [userInfo]);

  // Fetch global messages whenever `state` changes
  useEffect(() => {
    if (state) {
      dispatch(getGlobalMessages({ state }));
    }
  }, [state, dispatch]);

  useEffect(() => {
    setError(globalMessage.error);
  }, [globalMessage.error]);

  useEffect(() => {
    if (Array.isArray(globalMessage.messages)) {
      const sortedMessages = [...globalMessage.messages].sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );
      setConversations(sortedMessages);
    } else {
      setConversations([]);
    }
  }, [globalMessage.messages]);

  // Initialize socket connection
  useEffect(() => {
    socketRef.current = io(socketUrl, {
      secure: process.env.NODE_ENV === "production",
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  // Join room and handle state change
  useEffect(() => {
    if (!socketRef.current) return;

    if (
      socketRef.current.currentCity &&
      socketRef.current.currentCity !== state
    ) {
      socketRef.current.emit("leaveState", socketRef.current.currentCity);
    }

    try {
      socketRef.current.emit("joinState", state);
      socketRef.current.currentCity = state;
    } catch (error) {
      console.error("Error emitting joinState:", error);
    }
  }, [state, user, dispatch]);

  // Handle incoming messages
  useEffect(() => {
    if (!socketRef.current) return;

    const handleNewMessage = (newMessage) => {
      setConversations((prevMessages) => {
        const updatedMessages = [...prevMessages, newMessage];
        updatedMessages.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
        return updatedMessages;
      });
    };

    socketRef.current.on("newMessage", handleNewMessage);

    return () => {
      if (socketRef.current) {
        socketRef.current.off("newMessage", handleNewMessage);
      }
    };
  }, []);

  // Check if the user is near the bottom of the chat container
  const handleScroll = () => {
    if (!chatContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 200;
    setIsAtBottom(isNearBottom);
  };

  // Scroll to the last message only if the user is at the bottom
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true; // Set to true after the first render
      return; // Prevent scroll on initial load
    }

    if (isAtBottom && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [conversations, isAtBottom]);

  // Add scroll event listener to the chat container
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    chatContainer.addEventListener("scroll", handleScroll);
    return () => chatContainer.removeEventListener("scroll", handleScroll);
  }, []);
};

export const useGlobalChatFunctions = ({
  userColorRef,
  setError,
  text,
  setIsAtBottom,
  setText,
  setIsLoading,
  axios,
  apiUrl,
  state,
}) => {
  const getRandomColor = () => {
    const colors = [
      "rgb(255, 99, 71)", // Red (Tomato)
      "rgb(0, 128, 255)", // Blue
      "rgb(60, 179, 113)", // Green (Medium Sea Green)
      "rgb(255, 165, 0)", // Orange
      "rgb(128, 0, 128)", // Purple
      "rgb(75, 0, 130)", // Indigo
      "rgb(70, 130, 180)", // Steel Blue
      "rgb(0, 206, 209)", // Turquoise
      "rgb(240, 128, 128)", // Light Coral
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const getUserColor = (userId) => {
    if (!userColorRef.current[userId]) {
      const newColor = getRandomColor();
      userColorRef.current[userId] = newColor;
    }
    return userColorRef.current[userId];
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setError("");
    if (!text.trim()) return;
    setIsAtBottom(true);
    const sanitizedText = text.trim().replace(/(?:\s{2,})|(?:\n{2,})/g, "\n");
    setText("");

    setIsLoading(true);

    try {
      await axios.post(
        `${apiUrl}/globalMessages`,
        { state, sanitizedText },
        { withCredentials: true }
      );
      setError(""); // Clear any previous errors
    } catch (error) {
      setError(error.response?.data?.message || "Message failed to send.");
    } finally {
      setIsLoading(false);
    }
  };
  return {
    getUserColor,
    handleSendMessage,
  };
};
