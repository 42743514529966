import React, { useEffect, useState } from "react";
import { IoShareOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navigation from "../../../components/Navigation/Navigation";
import FooterTop from "../../../components/common/FooterTop";
import { Container, Col, Row } from "react-bootstrap";
import { MdDateRange } from "react-icons/md";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";
import { getBlogAndArticleDetails } from "../../../store/Actions/BlogsAndArticle";
import { apiUrl } from "../../../utils/apiUrl";

export default function NewsDetailedScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { blogsAndArticle } = useSelector((state) => state.blogsAndArticle);

  const [blogDetail, setBlogDetail] = useState({});

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      const el = document.createElement("textarea");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert(t("infoPage.URL copied to clipboard"));
    }
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    dispatch(getBlogAndArticleDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    setBlogDetail(blogsAndArticle);
  }, [blogsAndArticle]);
  console.log(blogsAndArticle);

  const seo = seoData.BlogDetailedScreen;
  return (
    <>
      <SEO
        title={`Stayforall Blog - ${blogDetail?.caption}`}
        description={blogDetail?.shortDetails}
        keywords={`Blog, Stayforall`}
        ogTitle={`Stayforall Blog - ${blogDetail?.caption}`}
        ogDescription={blogDetail?.shortDetails}
        ogImage={seo.ogImage}
        ogUrl={`${apiUrl}/blogs&article-Stayforall/${blogDetail?._id}`}
        twitterTitle={`Stayforall Blog - ${blogDetail?.caption}`}
        twitterDescription={blogDetail?.shortDetails}
        twitterImage={seo.twitterImage}
        canonicalUrl={`${apiUrl}/blogs&article-Stayforall/${blogDetail?._id}`}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={{
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          name: `Stayforall Blog - ${blogDetail?.caption}`,
          image: "/images/logo/SFAlargeLogo.webp",
          description: blogDetail?.shortDetails,
          datePublished: blogDetail?.createdAt,
          author: {
            "@type": "Person",
            name: "Stayforall",
          },
          brand: {
            "@type": "Brand",
            name: "Stayforall",
          },
          publisher: {
            "@type": "Organization",
            name: "Stayforall",
            logo: {
              "@type": "ImageObject",
              url: "/images/logo/SFAlargeLogo.webp",
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+49-176-87961367",
              contactType: "Customer Service",
              areaServed: "DE",
              availableLanguage: ["English", "German", "French"],
            },
            sameAs: [
              "https://www.instagram.com/stayforall.global/",
              "https://linktr.ee/stayforall?utm_source=linktree_profile_share&ltsid=b33c9245-9c5e-4fb1-b824-8947404390f2",
              "https://stayforall.com/",
              "https://www.instagram.com/stayforall_admissions/",
            ],
          },
          mainEntity: blogDetail?.faqs?.map((faq) => ({
            "@type": "Question",
            name: faq.question,
            acceptedAnswer: {
              "@type": "Answer",
              text: faq.answer,
            },
          })),
        }}
      />

      <Navigation />
      <Container className="min-h-[80vh] border-top border-bottom py-3 font-sans">
        <Row className="justify-center">
          <Col md={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(blogDetail?.caption),
              }}
            />
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center bg-light p-2 rounded my-3"
          >
            <p className="d-flex align-items-center mb-0">
              <MdDateRange className="mr-2" />{" "}
              {new Date(blogDetail?.createdAt).toLocaleDateString()}
            </p>
            <p
              onClick={handleShare}
              className="cursor-pointer d-flex align-items-center mb-0"
            >
              <IoShareOutline className="mr-2" /> {t("infoPage.Share")}
            </p>
          </Col>
          <Col md={12} lg={6}>
            <LazyLoad height={200} offset={100} once>
              <img
                src={blogDetail?.image}
                alt="News"
                className="img-fluid rounded"
              />
            </LazyLoad>
          </Col>
        </Row>

        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(blogDetail?.fullDetails),
          }}
        />
      </Container>

      <FooterTop bottom="bottom" />
    </>
  );
}
