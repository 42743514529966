import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import LargeScreenNav from "./components/LargeScreenNav";
import SmallScreenNav from "./components/SmallScreenNav";
import "../components.scss";

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scrollPosition = useScrollPosition();

  return (
    <>
      <div className={scrollPosition >= 260 ? "hide" : "homeNav2ndContainer"}>
        <LargeScreenNav t={t} navigate={navigate} dispatch={dispatch} />
      </div>

      <div
        className={`${
          scrollPosition >= 250 ? "shadow-xl homemovefix" : "homestablelargefix"
        }`}
      >
        <Nav className="largeNav">
          <SmallScreenNav t={t} navigate={navigate} dispatch={dispatch} />
        </Nav>
      </div>
    </>
  );
};

export default React.memo(Navigation);
