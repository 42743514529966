import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import "react-image-gallery/styles/scss/image-gallery.scss";
import RoomView from "../../../../components/Services/RoomView";
import { inDirectCategories } from "../../../../DataFiles/data";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import { GiBunkBeds } from "react-icons/gi";
import { AiTwotoneEuroCircle } from "react-icons/ai";
import { GiCash } from "react-icons/gi";
import { BsCalendarDate } from "react-icons/bs";
import { BsSendCheckFill } from "react-icons/bs";
import VerifiedStatus from "../../../../components/common/VerifiedStatus";

export default function Room({
  t,
  services,
  viewService,
  serviceId,
  handleView,
  handleIds,
  handleSubmit,
  interestError,
}) {
  const [rent, setRent] = useState(0);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [availableFrom, setAvailableFrom] = useState(null);
  const [serviceFee, setServiceFee] = useState(0);
  const [details, setDetails] = useState("");

  const roomSubmit = (e) => {
    e.preventDefault();

    const formData = {
      rent_response: rent,
      securityDeposit_response: securityDeposit,
      available_from: availableFrom,
      serviceFee_response: serviceFee,
      details_response: details,
    };

    handleSubmit(formData);
  };

  // useEffect
  useEffect(() => {
    setRent("");
    setSecurityDeposit("");
    setServiceFee("");
    setDetails("");
  }, [handleIds]);
  return (
    <>
      <div className="w-[100%] flex flex-column pt-3">
        {services?.map((e, i) => (
          <div
            key={`service${i}`}
            className="w-[100%] bg-white mb-6 shadow-md border rounded p-2"
          >
            <div
              // style={{ borderBottom: "1px solid rgba(206, 201, 201, 0.696)" }}
              className="flex justify-between items-center mb-2"
            >
              <div className=" flex items-start mt-1 pb-1">
                <img
                  className="shadow-sm w-[60px] h-[60px] p-2 border  rounded-[4px]"
                  src={e.profileImage}
                  alt="owner"
                />
                <div className="ml-3">
                  <div className="flex items-center font-bold">
                    <h5 className="font-bold">{e.name}</h5>
                    <VerifiedStatus isVerified={e.isVerified} />
                  </div>
                  <p className="m-0 p-0 text-secondary text-[15px]">{e.city}</p>
                </div>
              </div>

              <ul className="flex text-end">
                <li className="flex flex-column items-center mr-5">
                  <span className="font-bold text-[19px]">
                    {e.daysSinceCreated}
                  </span>
                  <span className="text-[#0CAFFF] text-[12px]">
                    {t("findWork.Day Ago")}
                  </span>
                </li>
                <li className="flex flex-column items-center">
                  <span className="font-bold text-[19px]">
                    {e.interestCount}
                  </span>{" "}
                  <span className="text-[#0CAFFF] text-[12px]">
                    {t("findWork.Responses")}
                  </span>
                </li>
              </ul>
            </div>

            <div>
              <div className="pt-2 grid md:grid-cols-2 lg:grid-cols-4">
                <p className="flex flex-col">
                  <span className="flex items-center text-[16px] font-bold text-secondary">
                    <BsCalendarDate className="mr-2" />{" "}
                    {t("findWork.Move in date")}
                  </span>
                  <span className="">
                    {new Date(e.moveInDate).toLocaleDateString("en-GB", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </span>
                </p>
                <p className="flex flex-col">
                  <span className="flex items-center text-[16px] font-bold text-secondary">
                    <GiCash className="mr-2" /> {t("findWork.Rent")}
                  </span>
                  <span className="">€{e.rent}</span>
                </p>
                <p className="flex flex-col">
                  <span className="flex items-center text-[16px] font-bold text-secondary">
                    <AiTwotoneEuroCircle className="mr-2" />
                    {t("findWork.Security Deposit")}
                  </span>
                  <span className="">€{e.securityDeposit}</span>
                </p>
                <p className="flex flex-col">
                  <span className="flex items-center text-[16px] font-bold text-secondary">
                    <GiBunkBeds className="mr-2" />
                    {t("findWork.Service fee")}
                  </span>
                  <span className="">{e.serviceFee}</span>
                </p>
              </div>
            </div>

            <div className="flex items-center justify-between pt-2">
              <div className="flex">
                {/* view the service details */}
                <button
                  onClick={() => handleView(e)}
                  className="text-[15px] underline px-2"
                >
                  {t("findWork.Details")}
                </button>
              </div>

              {/* apply the proposal */}
              <button
                onClick={() => handleIds(e.id)}
                className="flex items-center text-[16px] transition duration-200 rounded-[5px] bg-[#0CAFFF] hover:bg-[#36d0ff] px-2 py-[6px] text-white"
              >
                <BsSendCheckFill className="mr-2" />
                {t("findWork.Respond")}
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* proposal for service */}
      {serviceId && (
        <div className="popup-view">
          <div className="popView ">
            <p className="flex items-center mx-3 mt-3 font-bold">
              <img
                className="w-[60px]"
                src={inDirectCategories.Room.smallicon1}
                alt="small icon1"
              />
              <span className="ml-2">
                {t("findWork.Room")} {t("findWork.Details")}
              </span>
            </p>

            <Form onSubmit={roomSubmit} className="indirect_align mx-3 mt-5">
              <div className="bg-[#e9e9e9] rounded px-2 pb-3 pt-2 mb-3">
                <Form.Group className="my-3">
                  <Form.Label className="font-bold text-[14px] m-0 p-0">
                    {t("findWork.Rent")}
                    <span className="text-[red]">*</span>
                  </Form.Label>
                  <div className="border bg-white rounded-[15px] px-2 py-1">
                    <Form.Control
                      style={{ border: "none", boxShadow: "none" }}
                      onChange={(e) => setRent(e.target.value)}
                      value={rent}
                      type="number"
                      id="rent"
                      name="rent"
                      placeholder={t("findWork.Enter the room rent")}
                      required
                    />
                  </div>
                  <p className="text-[14px] text-secondary mt-1">
                    {t(
                      "findWork.Helps the tenant know the monthly rent they will be paying"
                    )}
                  </p>
                </Form.Group>

                <Form.Group className="my-3">
                  <Form.Label className="font-bold text-[14px] m-0 p-0">
                    {t("findWork.Security Deposit")}
                    <span className="text-[red]">*</span>
                  </Form.Label>
                  <div className="border bg-white rounded-[15px] px-2 py-1">
                    <Form.Control
                      style={{ border: "none", boxShadow: "none" }}
                      onChange={(e) => setSecurityDeposit(e.target.value)}
                      value={securityDeposit}
                      type="number"
                      id="Security Deposit"
                      name="Security Deposit"
                      placeholder={t("findWork.Enter the security deposit")}
                      required
                    />
                  </div>
                  <p className="text-[14px] text-secondary mt-1">
                    {t(
                      "findWork.Lets the tenant know the upfront cost required to secure the room"
                    )}
                  </p>
                </Form.Group>

                <Form.Group className="my-3">
                  <Form.Label className="font-bold text-[14px] m-0 p-0">
                    {t("findWork.Available from")}
                    <span className="text-[red]">*</span>
                  </Form.Label>
                  <div className="border bg-white rounded-[15px] px-2 py-1">
                    <Form.Control
                      style={{ border: "none", boxShadow: "none" }}
                      onChange={(e) => setAvailableFrom(e.target.value)}
                      value={availableFrom}
                      type="date"
                      id="Service Fee"
                      name="Service Fee"
                      required
                    />
                  </div>
                  <p className="text-[14px] text-secondary mt-1">
                    {t(
                      "findWork.Informs the tenant about the availability date of the property"
                    )}
                  </p>
                </Form.Group>

                <Form.Group className="my-3">
                  <Form.Label className="font-bold text-[14px] m-0 p-0">
                    {t("findWork.Service fee")}
                    <span className="text-[red]">*</span>
                  </Form.Label>
                  <div className="border bg-white rounded-[15px] px-2 py-1">
                    <Form.Control
                      style={{ border: "none", boxShadow: "none" }}
                      onChange={(e) => setServiceFee(e.target.value)}
                      value={serviceFee}
                      type="number"
                      id="Service Fee"
                      name="Service Fee"
                      placeholder={t("findWork.Enter the service fee")}
                      required
                    />
                  </div>
                  <p className="text-[14px] text-secondary mt-1">
                    {t(
                      "findWork.Informs the tenant of any additional fees for using services"
                    )}
                  </p>
                </Form.Group>

                <Form.Group className="my-3">
                  <Form.Label className="font-bold text-[14px] m-0 p-0">
                    {t("findWork.Details")}
                  </Form.Label>
                  <div className="border bg-white rounded-[15px] px-2 py-1">
                    <Form.Control
                      style={{ height: "60px" }}
                      onChange={(e) => setDetails(e.target.value)}
                      value={details}
                      as="textarea"
                      placeholder={t(
                        "findWork.Enter additional details about the room"
                      )}
                      className="border-0 shadow-none"
                      required
                    />
                  </div>
                  <p className="text-[14px] text-secondary mt-1">
                    {t(
                      "findWork.Provides the tenant with more information about the room, such"
                    )}
                  </p>
                </Form.Group>
              </div>
              <div className="col-span-2 flex items-center justify-end mb-3">
                <button
                  className="interestSubmit w-[100%] mt-3 d-flex items-center justify-center"
                  variant=""
                  type="submit"
                >
                  {t("findWork.Respond")}
                  <IoMdArrowDroprightCircle className="ml-2 text-[20px]" />
                </button>
              </div>
              {/* error */}
              {interestError && (
                <Alert variant="danger" className="col-span-2 mt-3">
                  {interestError}
                </Alert>
              )}
            </Form>
          </div>
          <div onClick={() => handleIds("")} className="closeBtn">
            <ImCancelCircle className=" hover:scale-105" />
          </div>
        </div>
      )}

      {/* view the room details */}
      <RoomView serviceView={viewService} handleServiceId={handleView} />
    </>
  );
}
