import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { apiUrl } from "../../../utils/apiUrl";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import ReactQuill from "react-quill";
import "../RoomListing.scss";
import { germanStates } from "../../../DataFiles/countries";

export default function SocialMediaRooms() {
  const [file, setFile] = useState({
    state: "",
    city: "",
    number: "",
    link: "",
    rent: "",
    detail: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const stateCities =
    germanStates.find((y) => y.sampleName === file.state)?.cities || [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    // Validation
    if (
      (!file.number && !file.link) || // At least one of these must be filled
      !file.state ||
      !file.city ||
      !file.rent ||
      !file.detail
    ) {
      setError(
        "Either a number or link is required, and all other fields must be filled."
      );
      setIsLoading(false);
      return;
    }
    try {
      await axios.post(
        `${apiUrl}/admin/room_listing/social_media_rooms`,
        file,
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      setError(error.response?.data?.message || "An unexpected error occurred");
    } finally {
      toast.success("Successfully created room listing");
      setFile({
        number: "",
        link: "",
        state: "",
        city: "",
        rent: "",
        detail: "",
      });
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="container my-5 ">
      <ToastContainer />
      <h2 className="mb-4">Create a social media room inputs</h2>
      <Form.Group className="mb-2">
        <Form.Label>State</Form.Label>
        <Form.Select
          value={file.state}
          onChange={(e) => setFile({ ...file, state: e.target.value })}
          required
        >
          <option hidden>Select State</option>
          {germanStates.map((e, i) => (
            <option key={i} value={e.sampleName}>
              {e.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>City</Form.Label>
        <Form.Select
          value={file.city}
          onChange={(e) => setFile({ ...file, city: e.target.value })}
          required
        >
          <option hidden>Select City</option>
          {stateCities.map((city, i) => (
            <option key={i} value={city}>
              {city}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Whatsapp number</Form.Label>
        <div className="border rounded">
          <PhoneInput
            country={"de"} // Default country
            value={file.number}
            onChange={(value) => setFile({ ...file, number: value })}
            placeholder="Enter Number"
            inputStyle={{ width: "100%", border: "none" }} // Custom styles for the input field
          />
        </div>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Link</Form.Label>
        <Form.Control
          type="text"
          value={file.link}
          onChange={(e) => setFile({ ...file, link: e.target.value })}
          placeholder="Enter Link"
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Rent</Form.Label>
        <Form.Control
          type="number"
          value={file.rent}
          onChange={(e) => setFile({ ...file, rent: e.target.value })}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Details</Form.Label>

        <ReactQuill
          theme="snow"
          value={file.detail}
          onChange={(value) => setFile({ ...file, detail: value })}
          modules={{ toolbar: false }}
          formats={[]}
          style={{
            fontFamily: "sans-serif",
            overflow: "auto",
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            padding: "10px",
            maxHeight: "150px",
          }}
        />
      </Form.Group>

      {error && <p className="my-2 text-danger">{error}</p>}

      <Button disabled={isLoading} className="mt-3" type="submit">
        {isLoading ? (
          <>
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
            />
            Submitting...
          </>
        ) : (
          "Submit"
        )}
      </Button>
    </Form>
  );
}
