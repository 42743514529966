import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AdminNavigation from "./AdminNavigation";
import { loadUser } from "../store/Actions/User";
import { useDispatch, useSelector } from "react-redux";

export default function AdminScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true); // Start loading
      await dispatch(loadUser());
      setLoading(false); // Stop loading after API call
    };

    fetchUser();
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !user.isAuthenticated) {
      navigate("/login");
    }
  }, [loading, user.isAuthenticated, navigate]);

  return (
    <div className="bg-[#a28089]">
      <AdminNavigation />
      <Container className="adminMainContainer">
        <section className="text-center border rounded p-5 shadow-md">
          <h3>SUPER ADMIN PANEL</h3>
          <Row className="justify-content-center mt-4">
            <Col md={3} className="d-flex justify-content-center">
              <Link to="/admin/dashboard" className="shadow-sm cursor-pointer">
                Dashboard
              </Link>
            </Col>
            <Col md={3} className="d-flex justify-content-center">
              <Link
                to="/admin/createAdmin"
                className="shadow-sm cursor-pointer"
              >
                Add Admin
              </Link>
            </Col>
          </Row>
        </section>

        <section className="mt-4 text-center border rounded p-5 shadow-md">
          <h3 className="mb-3">USERS PANEL</h3>
          <div className="flex flex-wrap justify-center mt-4">
            <Link to="/admin/users" className="shadow-sm cursor-pointer mr-3">
              Users
            </Link>
            <Link
              to="/admin/pendingVerifications"
              className="shadow-sm cursor-pointer"
            >
              Pending verifications
            </Link>
          </div>
        </section>

        <section className="mt-4 text-center border rounded p-3 shadow-md">
          <h5 className="mb-3">ROOMS</h5>
          <div className="flex flex-wrap justify-center mb-2 border-bottom pb-3">
            <Link
              to="/admin/social_media_rooms"
              className="shadow-sm cursor-pointer mr-3"
            >
              Social Media Rooms
            </Link>
            <Link to="/admin/users" className="shadow-sm cursor-pointer mr-3">
              Reports and Analytics
            </Link>
            <Link to="/admin/users" className="shadow-sm cursor-pointer mr-3">
              Requests
            </Link>
            <Link
              to="/admin/pendingVerifications"
              className="shadow-sm cursor-pointer"
            >
              Service Providers
            </Link>
          </div>
          <h5 className="my-3">ROOM VERIFICATION</h5>
          <div className="flex flex-wrap justify-center">
            <Link
              to="/admin/DServiceOverview/Room verification"
              className="shadow-sm cursor-pointer mr-3"
            >
              Reports and Analytics
            </Link>
            <Link
              to="/admin/DServiceRequests/Room verification"
              className="shadow-sm cursor-pointer mr-3"
            >
              Requests
            </Link>
            <Link
              to="/admin/DServiceServiceProviders/Room verification"
              className="shadow-sm cursor-pointer"
            >
              Service Providers
            </Link>
          </div>
        </section>

        <section className="my-5 text-center border rounded p-5 shadow-md">
          <h3>SEO PANEL</h3>
          <div className="flex flex-wrap justify-center">
            <Link
              to="/admin/blog?page=1"
              className="shadow-sm cursor-pointer mr-3"
            >
              Blogs
            </Link>
            <Link
              to="/admin/news?page=1"
              className="shadow-sm cursor-pointer mr-3"
            >
              News
            </Link>
            <Link to="/admin/webinar" className="shadow-sm cursor-pointer">
              Webinar
            </Link>
          </div>
        </section>
      </Container>
    </div>
  );
}
