import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { germanStates } from "../../../DataFiles/countries";
import { BsSend } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import Loading from "../../../components/samplePage/Loading";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { loadUser } from "../../../store/Actions/User";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalMessages } from "../../../store/Actions/chat";
import { apiUrl, socketUrl } from "../../../utils/apiUrl";
import { io } from "socket.io-client";
import { FaAnglesDown } from "react-icons/fa6";
import {
  useEffectGlobalChat,
  useGlobalChatFunctions,
} from "../hooks/useEffectGlobalChat";

export default function GlobalChat({ t }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socketRef = useRef(null);
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const userColorRef = useRef({});
  const hasMounted = useRef(false);

  const { user } = useSelector((state) => state.userInfo);
  const globalMessage = useSelector((state) => state.getGlobalMessage);

  const [userInfo, setUserInfo] = useState({});
  const [text, setText] = useState("");
  const [conversations, setConversations] = useState([]);
  const [state, setState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const { getUserColor, handleSendMessage } = useGlobalChatFunctions({
    userColorRef,
    setError,
    text,
    setIsAtBottom,
    setText,
    setIsLoading,
    axios,
    apiUrl,
    state,
  });

  useEffectGlobalChat({
    dispatch,
    loadUser,
    setUserInfo,
    user,
    userInfo,
    setState,
    state,
    getGlobalMessages,
    globalMessage,
    setError,
    setConversations,
    socketRef,
    io,
    socketUrl,
    chatContainerRef,
    setIsAtBottom,
    hasMounted,
    isAtBottom,
    lastMessageRef,
    conversations,
  });
  return (
    <div className="globalChatContainer">
      <section className="shadow-lg border rounded">
        {/* Chat Header */}
        <div className="bg-[#1e202d] flex items-center justify-between p-2 rounded-t mb-3">
          <div className="text-white md:text-[22px] flex items-center font-sans font-bold">
            <img
              className="w-[20px] mr-2"
              src="/images/icons/international.png"
              alt="chat"
            />
            {t("homePage.Global Chat")}
          </div>
          <div className="flex items-center bg-white rounded pl-2">
            <img
              className="w-[20px]"
              src="/images/icons/location.png"
              alt="state"
            />
            <Form.Select
              value={state}
              onChange={(e) => setState(e.target.value)}
              aria-label="Select State"
              className="border-0 shadow-none"
              style={{ maxWidth: "200px" }}
            >
              <option hidden key="null" value="">
                {t("homePage.Selelct State")}
              </option>
              {germanStates.map((e) => (
                <option key={e.sampleName} value={e.sampleName}>
                  {e.sampleName}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>

        {globalMessage.loading && (
          <Loading type="colone" color="#36d7b7" size={20} />
        )}
        {/* Chat Messages */}
        <div ref={chatContainerRef} className="global_messages_container">
          {conversations?.length === 0 ? (
            <div className="flex items-center justify-center text-center text-[30px] font-bold mx-3">
              {t("homePage.Welcome to Stayforall Global Chat")}
            </div>
          ) : (
            conversations?.map((message, index) => (
              <div
                key={index}
                ref={index === conversations.length - 1 ? lastMessageRef : null}
                className={`w-full flex ${
                  message.senderId.toString() === user?.id.toString()
                    ? "justify-end"
                    : "justify-start"
                }`}
              >
                <div
                  className={`${
                    message.senderId.toString() === user?.id.toString()
                      ? "bg-[#092c4175] text-white rounded-l rounded-br"
                      : "bg-[#adaeaf77] text-[#1F2937] rounded-r rounded-bl"
                  } min-w-[30%] max-w-[50%] m-2 p-2`}
                >
                  {message.senderId.toString() !== user?.id.toString() && (
                    <h6
                      style={{ color: getUserColor(message.senderId) }}
                      className="font-bold cursor-pointer hover:underline"
                    >
                      {message.senderName}
                    </h6>
                  )}
                  <div
                    style={{ fontSize: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(message.message || "", {
                        ALLOWED_TAGS: ["b", "i", "u", "a", "p"],
                        ALLOWED_ATTR: ["href"],
                      }),
                    }}
                  />
                  <div className="text-end text-secondary text-[12px]">
                    {new Intl.DateTimeFormat("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(message.timestamp))}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        {!isAtBottom && (
          <div className="flex items-center justify-center">
            <div onClick={() => setIsAtBottom(true)} className="absolute">
              <FaAnglesDown className=" text-[20px] translate-y-[-10px] " />
            </div>
          </div>
        )}

        {error && (
          <Alert className="mx-2 my-0" key="danger" variant="danger">
            {error}
          </Alert>
        )}

        {/* Chat Input */}
        {!user ? (
          <div
            onClick={() => navigate("/login")}
            className="text-center bg-[#7a9eec] text-[#ffffff] m-2 font-bold rounded py-[6px] cursor-pointer hover:bg-[#5381e6]"
          >
            {t("homePage.Login to continue chat")}
          </div>
        ) : (
          <Form
            className="d-flex items-end justify-between p-3"
            onSubmit={handleSendMessage}
          >
            <Form.Group className="w-full border rounded">
              <ReactQuill
                theme="snow"
                value={text}
                onChange={setText}
                placeholder="Write your message..."
                modules={{ toolbar: false }}
                formats={[]}
                style={{
                  fontFamily: "sans-serif",
                  overflow: "auto",
                  border: "1px solid #ced4da",
                  borderRadius: "0.25rem",
                  padding: "10px",
                  maxHeight: "150px",
                }}
              />
            </Form.Group>
            <button
              aria-label="Send message"
              type="submit"
              className={`rounded-circle ml-2 p-[9px] shadow-sm ${
                isLoading ? "bg-[#f3f3f3]" : "bg-[#83e2da]"
              }`}
              disabled={isLoading} // Disable button during loading
            >
              {isLoading ? (
                <Loading type="colTwo" color="#36d7b7" size={25} margin={0} />
              ) : (
                <BsSend className="text-[18px] text-white" />
              )}
            </button>
          </Form>
        )}
      </section>
    </div>
  );
}
