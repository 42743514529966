import { Route, Routes } from "react-router-dom";
import "./App.scss";
import HomeScreen from "./pages/Homepage/HomeScreen";
import AboutScreen from "./pages/Infopages/AboutScreen";
import CareerScreen from "./pages/Infopages/CareerScreen";
import LoginScreen from "./pages/LoginPage/LoginScreen";
import { useSelector } from "react-redux";
import InDirectServicePage from "./pages/inDirectServicePage/InDirectServicePage";
import FAQSPage from "./pages/Infopages/FAQSPage";
import SignUpScreen from "./pages/SignUpPage/SignUpScreen";
import UserProfile from "./pages/ProfilePage/UserProfile";
import NewPasswordPage from "./pages/NewPasswordPage/NewPasswordPage";
import NewPasswordPending from "./pages/NewPasswordPage/NewPasswordPending";
import NewPasswordSuccess from "./pages/NewPasswordPage/NewPasswordSuccess";
import { HelmetProvider } from "react-helmet-async";
import WebinarScreen from "./pages/Infopages/WebinarPage/WebinarScreen";
import NewsScreen from "./pages/Infopages/NewsPage/NewsScreen";
import BlogScreen from "./pages/Infopages/Blogpage/BlogScreen";
import BlogDetailedScreen from "./pages/Infopages/Blogpage/BlogDetailedScreen";
import NewsDetailedScreen from "./pages/Infopages/NewsPage/NewsDetailedScreen";
import "react-toastify/dist/ReactToastify.css";
import Error404Page from "./pages/AllOtherPages/Error404Page";
import ChatPage from "./pages/ChatPage/ChatPage";
import TermsAndCondition from "./pages/Policies/TermsAndCondition";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import CookiesPolicy from "./pages/Policies/CookiesPolicy";
import DirectServicePage from "./pages/DirectServicePage/DirectServicePage";
import BlogPage from "./ADMIN/infoPages/BlogPage";
import NewsPage from "./ADMIN/infoPages/NewsPage";
import WebinarPage from "./ADMIN/infoPages/WebinarPage";
import AdminScreen from "./ADMIN/AdminScreen";
import DashboardPage from "./ADMIN/dashboard/DashboardPage";
import UserList from "./ADMIN/user/UserList";
import UserProfileInfo from "./ADMIN/user/UserProfileInfo";
import PendingVerifications from "./ADMIN/user/PendingVerifications";
import CreateRoomPage from "./ADMIN/roomListing/AddRoomPage/CreateRoomPage";
import SocialMediaRooms from "./ADMIN/roomListing/SocialMediaRooms/SocialMediaRooms";
import CreateAdminPage from "./ADMIN/createAdmin/CreateAdminPage";
import DServiceOverview from "./ADMIN/directService/DServiceOverview";
import DServiceRequests from "./ADMIN/directService/DServiceRequests";
import DServiceDetail from "./ADMIN/directService/DServiceDetail";
import ContactScreen from "./pages/Infopages/ContactScreen";
import CancellationandRefund from "./pages/Policies/CancellationandRefund";
import CustomCookieConsent from "./components/common/CustomCookieConsent";

function App() {
  const user = useSelector((state) => state.user);

  return (
    <HelmetProvider>
      <CustomCookieConsent />
      <Routes>
        <Route path="/">
          {/* HomeScreen */}
          <Route index element={<HomeScreen />} />

          {/* inDirect service screens */}
          <Route
            path="inDirectServicePage/:item"
            element={<InDirectServicePage user={user} />}
          />

          {/* Direct service screens */}
          <Route
            path="directServicePage/:item"
            element={<DirectServicePage />}
          />

          {/* chat Page */}
          <Route
            path="/chat/:service/:interestId/:receiverId"
            element={<ChatPage />}
          />

          {/* Login and signup route */}
          <Route path="register" element={<SignUpScreen />} />
          <Route path="login" element={<LoginScreen />} />
          <Route
            path="login/passwordRecovery/:email"
            element={<NewPasswordPending />}
          />
          <Route path="reset-password/:token" element={<NewPasswordPage />} />
          <Route
            path="login/recoverySuccess"
            element={<NewPasswordSuccess />}
          />

          {/* terms and conditions Page */}
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route
            path="/cancellationandRefund-policy"
            element={<CancellationandRefund />}
          />

          {/*user profile pages*/}

          <Route path="myProfile/:category" element={<UserProfile />} />

          {/* about and detailed screens */}
          <Route path="about-us-Stayforall" element={<AboutScreen />} />
          <Route path="career-Stayforall" element={<CareerScreen />} />
          <Route path="/contact-us" element={<ContactScreen />} />

          {/* news page */}
          <Route path="news-Stayforall" element={<NewsScreen />} />
          <Route path="news-Stayforall/:id" element={<NewsDetailedScreen />} />

          {/* blogsAndArticles page */}
          <Route path="blogs&article-Stayforall" element={<BlogScreen />} />
          <Route
            path="blogs&article-Stayforall/:id"
            element={<BlogDetailedScreen />}
          />

          {/* faqs and webinars */}
          <Route path="FAQS-Stayforall" element={<FAQSPage />} />
          <Route path="webinars-Stayforall" element={<WebinarScreen />} />

          {/* All Other Pages */}
          <Route path="/:allOtherPages" element={<Error404Page />} />
        </Route>

        {/* ADMIN PAGES */}
        <Route path="/admin">
          <Route index element={<AdminScreen />} />

          {/* Dashboard of admin section */}
          <Route path="dashboard" element={<DashboardPage />} />

          {/* Create admin for each roles */}
          <Route path="createAdmin" element={<CreateAdminPage />} />

          {/* Users Routes */}
          <Route path="users" element={<UserList />} />
          <Route path="user/:userId" element={<UserProfileInfo />} />
          <Route
            path="pendingVerifications"
            element={<PendingVerifications />}
          />

          {/* DirectService Admin Routes */}
          <Route
            path="DServiceOverview/:serviceName"
            element={<DServiceOverview />}
          />
          <Route
            path="DServiceRequests/:serviceName"
            element={<DServiceRequests />}
          />
          <Route
            path="DServiceDetail/:serviceName/:id"
            element={<DServiceDetail />}
          />

          {/* List rooms */}
          <Route path="roomListing" element={<CreateRoomPage />} />
          <Route path="social_media_rooms" element={<SocialMediaRooms />} />

          {/* Blog, News, Webinar pages */}
          <Route path="blog" element={<BlogPage />} />
          <Route path="news" element={<NewsPage />} />
          <Route path="webinar" element={<WebinarPage />} />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
