import React, { memo, useCallback } from "react";
import { PiWarningOctagonBold } from "react-icons/pi";
import { IoIosTimer } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import LazyLoad from "react-lazyload";
import Loading from "../../../components/samplePage/Loading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GiMoneyStack } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoChatboxOutline } from "react-icons/io5";
import { LuPhoneOutgoing } from "react-icons/lu";
import VerifiedStatus from "../../../components/common/VerifiedStatus";

const Interests = ({
  t,
  serviceName,
  status,
  category,
  serviceInterests,
  serviceIdSelect,
  handleInterestView,
  handleSwitchChange,
  interestStatusSelect,
  selectedService,
  accepted,
  error,
}) => {
  const navigate = useNavigate();
  const othersInterests = useSelector((state) => state.othersInterests);

  const renderInterestStatusSwitch = useCallback(
    () => (
      <div className="mx-2 cursor-pointer flex items-center">
        <p
          className={`${
            interestStatusSelect === "pending"
              ? "bg-[#4475b4] shadow-md"
              : "bg-grey hover:scale-105 hover:shadow-md"
          } text-[white] mr-2 rounded px-2 py-1 text-[18px]`}
          onClick={handleSwitchChange}
        >
          <span>
            {interestStatusSelect === "pending"
              ? serviceInterests?.length
              : selectedService?.interestCount - serviceInterests?.length}
          </span>{" "}
          {t("inDirectServicePage.pending")}
        </p>
        <p
          className={`${
            interestStatusSelect === "accepted"
              ? "bg-[#4475b4] shadow-md"
              : "bg-grey hover:scale-105 hover:shadow-md"
          } text-[white] rounded px-2 py-1 text-[18px]`}
          onClick={handleSwitchChange}
        >
          <span>
            {interestStatusSelect === "accepted"
              ? serviceInterests?.length
              : selectedService?.interestCount - serviceInterests?.length}
          </span>{" "}
          {t("inDirectServicePage.accepted")}
        </p>
      </div>
    ),
    [status, interestStatusSelect, handleSwitchChange]
  );

  const renderInterests = useCallback(
    () =>
      serviceInterests?.map((e, i) => (
        <div
          key={`interest${i}`}
          className="bg-[white] pt-3 px-2 mb-3 border rounded shadow-md items-center"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-start">
              {e.status === "pending" ? (
                <IoIosTimer className="text-[19px] translate-x-2" />
              ) : e.status === "accepted" ? (
                <TiTick className="text-[19px] translate-x-2" />
              ) : null}
              <div className="flex items-start">
                <LazyLoad height={200} offset={100} once>
                  <img
                    src={e.profileImage}
                    alt="Interested user"
                    className="w-[35px]"
                  />
                </LazyLoad>
                <div className="ml-2 text-[18px]">
                  <div className="flex items-center text-nowrap">
                    {e?.name}
                    <VerifiedStatus isVerified={e?.isVerified} />
                  </div>
                  <span className="text-[15px] text-secondary">
                    {e.preferredState}
                  </span>
                </div>
              </div>
            </div>
            <div
              onClick={() => handleInterestView(e.id)}
              className="flex flex-column items-center cursor-pointer"
            >
              <IoIosArrowRoundForward className="border rounded-circle p-1 text-[30px] bg-[#c9c9c9] text-[#4b4b4b]" />
              <span className="text-[11px]">
                {t("inDirectServicePage.View")}
              </span>
            </div>
          </div>
          <div className="text-center bg-[#e7e7e7] rounded-[10px] mt-4 mb-3">
            <div className="flex text-center">
              <div className="w-[50%] flex items-center rounded-[10px] bg-[white] m-2 py-1">
                <div className="flex items-start">
                  <GiMoneyStack className="text-[30px] bg-[#84caf3a6] p-1 rounded-circle ml-3 mr-1" />
                  <div className="flex flex-column items-center justify-center text-break">
                    <p className="flex p-0 m-0 text-[13px] text-secondary">
                      {t("inDirectServicePage.Rent")}
                    </p>
                    <h4>€ {e.rent_response}</h4>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center py-3 rounded-[10px]">
                <div className="flex items-start">
                  <GiTakeMyMoney className="text-[30px] bg-[#9d90e9bb] p-1 rounded-circle ml-3 mr-1" />
                  <div className="flex flex-column items-center justify-center text-break">
                    <span className="text-[13px] text-secondary">
                      {t("inDirectServicePage.Service fee")}
                    </span>
                    <span>€ {e.serviceFee_response}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {accepted && (
            <div
              onClick={() =>
                navigate(`/chat/${category}/${e.id}/${e.interested_by}`)
              }
              className="flex items-center justify-center font-bold text-white cursor-pointer bg-[#4d7777] px-2 py-1 rounded mb-3"
            >
              <IoChatboxOutline className="hover:scale-105 mr-2" />
              {t("inDirectServicePage.Chat Now")}
            </div>
          )}
        </div>
      )),
    [serviceInterests, status, category, navigate, handleInterestView, t]
  );

  return (
    <>
      {serviceIdSelect !== "" && (
        <div className="flex items-start mx-2 mt-3 mb-2 pb-2">
          {/* {status === "pending" && (
          <> */}
          {renderInterestStatusSwitch()}
          <h5 className="text-secondary pt-2 md:text-[20px]">{}</h5>
          {/* </>
        )} */}
        </div>
      )}
      <div className="flex mx-2 mb-5 mt-2 p-2 ">
        {othersInterests.loading ? (
          <Loading
            className="flex items-center justify-center"
            type="colTwo"
            color="#36d7b7"
            size={40}
            margin={5}
          />
        ) : (
          <>
            {serviceIdSelect === "" ? (
              <h4 className="w-[100%] text-center text-secondary mt-3 mb-6 pt-4">
                {t(
                  "inDirectServicePage.Select one of your requests, accept the"
                )}
              </h4>
            ) : (
              <div className="w-[100%] grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {serviceInterests?.length ? (
                  renderInterests()
                ) : interestStatusSelect == "pending" ? (
                  <h4 className="col-span-4 text-secondary text-center my-6 p-3">
                    {t("inDirectServicePage.Your request for a")} {category}{" "}
                    {t("inDirectServicePage.has been posted. Wait")}
                  </h4>
                ) : (
                  <h4 className="col-span-4 text-secondary text-center my-6 p-3">
                    {t("inDirectServicePage.Accept a response in order")}
                  </h4>
                )}
              </div>
            )}
            {error && (
              <div id="error" className="flex justify-center text-danger">
                <PiWarningOctagonBold className="mr-2 text-[25px]" />
                {error}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(Interests);
