import React, { useEffect, useState } from "react";
import { IoShareOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navigation from "../../../components/Navigation/Navigation";
import FooterTop from "../../../components/common/FooterTop";
import { Container, Col, Row } from "react-bootstrap";
import { MdDateRange } from "react-icons/md";
import { getNews } from "../../../store/Actions/News";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import { apiUrl } from "../../../utils/apiUrl";
import DOMPurify from "dompurify";

import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";

export default function NewsDetailedScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [news, setNews] = useState(null);
  const { allNews } = useSelector((state) => state.allNews);

  const handleShare = () => {
    if ("share" in navigator) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      const el = document.createElement("textarea");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert(t("infoPage.URL copied to clipboard"));
    }
  };

  // useEffects
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    dispatch(getNews(id));
  }, [id, dispatch]);

  useEffect(() => {
    setNews(allNews);
  }, [allNews]);

  const seo = seoData.NewsDetailedScreen;
  return (
    <>
      <SEO
        title={`Stayforall News - ${news?.caption}`}
        description={news?.shortDetails}
        keywords={`News, Stayforall`}
        ogTitle={`Stayforall News - ${news?.caption}`}
        ogDescription={news?.shortDetails}
        ogImage={seo.ogImage}
        ogUrl={`${apiUrl}/news-Stayforall/${news?._id}`}
        twitterTitle={`Stayforall News - ${news?.caption}`}
        twitterDescription={news?.shortDetails}
        twitterImage={seo.twitterImage}
        canonicalUrl={`${apiUrl}/news-Stayforall/${news?._id}`}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={{
          "@context": "http://schema.org",
          "@type": "NewsArticle",
          name: `Stayforall News - ${news?.caption}`,
          image: "/images/logo/SFAlargeLogo.webp",
          description: news?.shortDetails,
          brand: {
            "@type": "Brand",
            name: "Stayforall",
          },
        }}
      />

      <Navigation />
      <Container className="min-h-[80vh] border-top border-bottom py-3 font-sans">
        <Row className="justify-center">
          <Col md={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(news?.caption),
              }}
            />
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center bg-light p-2 rounded my-3"
          >
            <p className="d-flex align-items-center mb-0">
              <MdDateRange className="mr-2" />{" "}
              {new Date(news?.createdAt).toLocaleDateString()}
            </p>
            <p
              onClick={handleShare}
              className="cursor-pointer d-flex align-items-center mb-0"
            >
              <IoShareOutline className="mr-2" /> {t("infoPage.Share")}
            </p>
          </Col>
          <Col md={12} lg={6}>
            <LazyLoad height={200} offset={100} once>
              <img src={news?.image} alt="News" className="img-fluid rounded" />
            </LazyLoad>
          </Col>
          <Col md={12} className="my-3">
            <p>{news?.shortDetails}</p>
          </Col>
        </Row>

        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(news?.fullDetails),
          }}
        />
      </Container>

      <FooterTop bottom="bottom" />
    </>
  );
}
