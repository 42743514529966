import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../utils/apiUrl";

// Fetch receiver details by ID
export const authReceiver = createAsyncThunk(
  "authReceiver",
  async (receiverId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/receiverInfo/${receiverId}`, {
        withCredentials: true,
      });
      return data.receiver;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// authChatSlice definition
const authChatSlice = createSlice({
  name: "Auth Chat",
  initialState: {
    loading: false,
    error: null,
    receiver: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authReceiver.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.receiver = null;
      })
      .addCase(authReceiver.fulfilled, (state, action) => {
        state.loading = false;
        state.receiver = action.payload;
      })
      .addCase(authReceiver.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Get all messages for a given receiver, service, and interest
export const getMessages = createAsyncThunk(
  "getAllMessages",
  async ({ receiverId, service, interestId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/messages/${service}/${receiverId}/${interestId}`,
        {
          withCredentials: true,
        }
      );
      return data?.messages;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// getAllMessagesSlice definition
const getAllMessagesSlice = createSlice({
  name: "Get All Messages",
  initialState: {
    loading: false,
    error: null,
    messages: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Send a message to a given receiver for a specific service
export const sendMessage = createAsyncThunk(
  "sendMessage",
  async ({ formData, service, receiverId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/messages/${receiverId}/${service}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      return data.newMessage;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// sendMessageSlice definition
const sendMessageSlice = createSlice({
  name: "Send Message",
  initialState: {
    loading: false,
    error: null,
    newMessage: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.newMessage = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.newMessage = action.payload;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Fetch message notifications (e.g., unread conversations)
export const messageNotification = createAsyncThunk(
  "messageNotification",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/message/notification`, {
        withCredentials: true,
      });
      return data.unreadConversationsByUser;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// blogsAndArticleSlice definition
const messageNotificationSlice = createSlice({
  name: "blogsAndArticle",
  initialState: {
    loading: false,
    error: null,
    messageNotifications: [],
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(messageNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messageNotifications = null;
      })
      .addCase(messageNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.messageNotifications = action.payload;
      })
      .addCase(messageNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Get all messages for a given receiver, service, and interest
export const getGlobalMessages = createAsyncThunk(
  "getGlobalMessages",
  async ({ state }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/globalMessages/${state}`, {
        withCredentials: true,
      });
      return data?.messages;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// getAllMessagesSlice definition
const getAllGlobalMessagesSlice = createSlice({
  name: "Get All Global Messages",
  initialState: {
    loading: false,
    error: null,
    messages: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
      })
      .addCase(getGlobalMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload;
      })
      .addCase(getGlobalMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearErrors: clearAuthReceiverErrors } = authChatSlice.actions;
export const { clearErrors: clearGetMessagesErrors } =
  getAllMessagesSlice.actions;
export const { clearErrors: clearSendMessageErrors } = sendMessageSlice.actions;
export const { clearErrors: clearMessageNotificationErrors } =
  messageNotificationSlice.actions;
export const { clearErrors: clearGetGloablMessagesErrors } =
  getAllGlobalMessagesSlice.actions;

export const authChatReducer = authChatSlice.reducer;
export const getAllMessagesReducer = getAllMessagesSlice.reducer;
export const sendMessageReducer = sendMessageSlice.reducer;
export const messageNotificationReducer = messageNotificationSlice.reducer;
export const getAllGlobalMessagesReducer = getAllGlobalMessagesSlice.reducer;
