import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { logoutUser } from "../../store/Actions/User";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { serviceHomeDetails } from "../../DataFiles/data";

export default function NavProfile({ t, category }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let query = new URLSearchParams(location.search);

  const service = query.get("service");
  const workStatus = query.get("work_status");

  const logoutHandler = () => {
    dispatch(logoutUser())
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {}, [service]);

  return (
    <>
      <ToastContainer position="bottom-center" autoClose={2000} />
      <>
        {/* for display greater than 600px */}
        <div className="sideNav_profile  overflow-y-scroll shadow-md select-none">
          <Nav className="d-flex flex-column justify-between px-3 z-1">
            <Nav.Item className="">
              <Nav.Link
                aria-label="Home"
                href="/"
                className="d-flex item-center justify-center rounded mt-3 mb-4"
              >
                <img
                  className="w-[150px]    rounded p-1"
                  alt=""
                  src="/images/logo/SFAlargeLogo.webp"
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mb-5">
              <h6 className=" font-bold text-[black]">
                {t("findWork.Services")}{" "}
              </h6>
              <div>
                {serviceHomeDetails.map((e, i) => (
                  <div
                    key={`selectService${i}`}
                    className={`${
                      service == e.title && category == "find_work"
                        ? "shadow-md rounded bg-[#0CAFFF] text-white font-bold"
                        : ""
                    } flex items-center p-2`}
                    onClick={() =>
                      navigate(
                        `/myProfile/find_work?service=${e.title}&pageNumber=1`
                      )
                    }
                  >
                    <img
                      className="w-[30px] h-[30px] mr-2"
                      src={`/images/smallicons/${e.title}.png`}
                      alt=""
                    />{" "}
                    {t(`findWork.${e.title}`)}
                  </div>
                ))}
              </div>
            </Nav.Item>
            <Nav.Item className="mb-5">
              <h6 className=" font-bold text-[black]">
                {t("findWork.Room")} {t(`findWork.Responses`)}
              </h6>

              <div>
                {["pending", "accepted"].map((e, i) => (
                  <div
                    key={`selectStatus${i}`}
                    className={`${
                      workStatus == e
                        ? "shadow-md rounded bg-[#249dda] text-white font-bold"
                        : ""
                    } flex items-center p-2 mb-1`}
                    onClick={() =>
                      navigate(
                        `/myProfile/workStatus?service=Room&work_status=${e}`
                      )
                    }
                  >
                    <img
                      className="w-[18px] h-[18px] mr-2"
                      src={`/images/smallicons/${e}.png`}
                      alt=""
                    />{" "}
                    {t(`findWork.${e}`)}
                  </div>
                ))}
              </div>
            </Nav.Item>

            <Nav.Item>
              <h6 className="font-bold text-[black]">{t("findWork.Others")}</h6>
              <div
                className={`${
                  category == "profile"
                    ? "shadow-md rounded bg-[#0CAFFF] text-white font-bold"
                    : ""
                } flex items-center p-2`}
                onClick={() => navigate("/myProfile/profile")}
              >
                <img
                  className="w-[20px] mr-2"
                  src="/images/smallicons/user.png"
                  alt="user"
                />
                {t("findWork.Profile")}
              </div>
              <div className="flex items-center p-2" onClick={logoutHandler}>
                <img
                  className="w-[20px] mr-2"
                  src="/images/smallicons/logout.png"
                  alt="user"
                />
                {t("findWork.Logout")}
              </div>
            </Nav.Item>
          </Nav>
        </div>
      </>

      {/* for display lesser than 600px */}

      <>
        <div className="smallNavDashboard ">
          <div className="w-[100%] flex justify-around items-center py-[7px]">
            <div
              onClick={() => {
                navigate("/myProfile/find_work?service=Room&pageNumber=1");
              }}
              className={`${
                category == "find_work"
                  ? "bg-[#0cafff] text-white shadow-lg rounded-[10px] font-bold"
                  : "shadow-sm rounded-circle"
              } flex items-center px-2 py-2 cursor-pointer`}
            >
              <img
                className="w-[22px] h-[22px]"
                src="/images/smallicons/Room.png"
                alt="find Job"
              />
              {category == "find_work" && (
                <span className="text-[15px] ml-2">Rooms</span>
              )}
            </div>

            <div
              onClick={() => {
                navigate(
                  "/myProfile/workStatus?service=Room&work_status=pending"
                );
              }}
              className={`${
                category == "workStatus" && workStatus == "pending"
                  ? "bg-[#0cafff] text-white shadow-lg rounded-[10px] font-bold"
                  : "shadow-md rounded-circle"
              } flex items-center px-2 py-2 cursor-pointer`}
            >
              <img
                className="w-[22px] h-[22px]"
                src="/images/smallicons/pending.png"
                alt="job Status"
              />

              {category == "workStatus" && workStatus == "pending" && (
                <span className="text-[15px] ml-2">Pending</span>
              )}
            </div>

            <div
              onClick={() => {
                navigate(
                  "/myProfile/workStatus?service=Room&work_status=accepted"
                );
              }}
              className={`${
                category == "workStatus" && workStatus == "accepted"
                  ? "bg-[#0cafff] text-white shadow-lg rounded-[10px] font-bold"
                  : "shadow-md rounded-circle"
              } flex items-center px-2 py-2 cursor-pointer`}
            >
              <img
                className="w-[22px] h-[22px]"
                src="/images/smallicons/accepted.png"
                alt="job Status"
              />

              {category == "workStatus" && workStatus == "accepted" && (
                <span className="text-[15px] ml-2">Accepted</span>
              )}
            </div>

            <div
              onClick={() => {
                navigate("/myProfile/profile");
              }}
              className={`${
                category == "profile"
                  ? "bg-[#0cafff] text-white shadow-lg rounded-[10px] font-bold"
                  : "shadow-md rounded-circle"
              } flex items-center px-2 py-2 cursor-pointer`}
            >
              <img
                className="w-[22px] h-[22px]"
                src="/images/smallicons/user.png"
                alt="user"
              />

              {category == "profile" && (
                <span className="text-[15px] ml-2">
                  {t("findWork.Profile")}
                </span>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
}
