import React from "react";
import { ImCancelCircle } from "react-icons/im";
import LazyLoad from "react-lazyload";
import { LuBadgeInfo } from "react-icons/lu";
import { CiWarning } from "react-icons/ci";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Ensure CSS import for react-confirm-alert
import VerifiedStatus from "../../../components/common/VerifiedStatus";

export default function CompleteSelect({
  t,
  item,
  category,
  serviceInterests,
  handleCompleteSelect,
  handleServiceComplete,
}) {
  const submit = (title, message, onClickYes) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: t("inDirectServicePage.Yes, I do"),
          onClick: onClickYes,
        },
        {
          label: t("inDirectServicePage.No, I do'nt"),
          onClick: () => {}, // Optional handler for No button
        },
      ],
    });
  };

  return (
    <div className="popup-view">
      <div className="popView p-3">
        <div className="flex items-start">
          <img
            className="w-[60px] h-[60px] ml-2"
            src={item}
            alt={`${item.name} icon1`}
          />
          <ul className="translate-x-[-10px]">
            <li className="font-bold text-[23px]">
              {t("inDirectServicePage.Complete")} {category}{" "}
              {t("inDirectServicePage.Service")}
            </li>
            <li className="flex items-center text-secondary text-[14px]">
              <LuBadgeInfo className="mr-2" />
              {t("inDirectServicePage.select one accepted interest")}
            </li>
          </ul>
        </div>
        {serviceInterests.length === 0 ? (
          <div className="h-[70%] text-[grey] font-bold text-[20px] flex flex-col items-center justify-center text-center">
            <img
              src="/images/smallicons/completeWarning.png"
              alt=""
              className="w-25 mb-4"
            />{" "}
            <span className="max-w-[400px]">
              {t(
                "inDirectServicePage.There is a complete absence of interest in seeing"
              )}
            </span>
          </div>
        ) : (
          serviceInterests.map((e, i) => (
            <div
              key={`acceptedInterest${i}`}
              onClick={() =>
                submit(
                  t("inDirectServicePage.Confirm to Complete Service"),
                  `${t(
                    "inDirectServicePage.Are you sure to complete your"
                  )} ${category} ${t("inDirectServicePage.Service")}.`,
                  () => handleServiceComplete(e.id)
                )
              }
              className="interestItemsPopup border rounded"
            >
              <div className="flex items-center ">
                <LazyLoad height={200} offset={100} once>
                  <img
                    src={e.profileImage}
                    alt="Interested user"
                    className="w-[35px]"
                  />
                </LazyLoad>

                <div className="ml-2">
                  <div className="flex items-center text-nowrap">
                    {e?.name}
                    <VerifiedStatus isVerified={e?.isVerified} />
                  </div>
                  <span className="text-[12px] text-secondary">
                    {e.preferredState}
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-center">
                  <span className="text-[12px] text-secondary">
                    {t("inDirectServicePage.Rent")}
                  </span>
                  <br />
                  {e.rent_response} $
                </div>
                <div className="text-center">
                  <span className="text-[12px] text-secondary">
                    {t("inDirectServicePage.Deposit")}
                  </span>
                  <br />
                  {e.securityDeposit_response} $
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div onClick={handleCompleteSelect} className="closeBtn">
        <ImCancelCircle className="hover:scale-105" />
      </div>
    </div>
  );
}
