import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OthersInDService,
  createInterest,
} from "../../../store/Actions/InDirectService";
import "./FindWork.scss";
import { GrLinkPrevious } from "react-icons/gr";
import { GrLinkNext } from "react-icons/gr";
import { germanStates } from "../../../DataFiles/countries";
import Filtering from "./components/Filtering";
import Room from "./services/Room";
import Loading from "../../../components/samplePage/Loading";
import { toast } from "react-toastify";
import { logoutUser } from "../../../store/Actions/User";
import { FaFilterCircleDollar } from "react-icons/fa6";
import { useEffectFindWork } from "../hooks/useEffectFindWork";
import { Col, Row, Form, Button, Alert } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";
import VerifiedStatus from "../../../components/common/VerifiedStatus";
import { IoAdd } from "react-icons/io5";
import { MdOutlineLogout } from "react-icons/md";
import MessageNotification from "../../../components/Navigation/components/MessageNotification";

export default function FindWork({ t }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let query = new URLSearchParams(location.search);

  const user = useSelector((state) => state.user);
  const inDService = useSelector((state) => state.inDService);
  const othersInDService = useSelector((state) => state.othersInDService);
  const interest = useSelector((state) => state.interest);
  const userInfo = useSelector((state) => state.userInfo);

  // useState for room sorting
  const [pageNumber, setPageNumber] = useState(query.get("pageNumber") || 1);
  const [state, setState] = useState(query.get("state") || "");
  const [city, setCity] = useState(query.get("city") || "");
  const [uploadDate, setUploadDate] = useState(query.get("uploadDate") || "-1");
  const [moveInDate, setMoveInDate] = useState(query.get("moveInDate") || "");
  const [propertyType, setPropertyType] = useState(
    query.get("propertyType") || ""
  );
  const [numberOfBedrooms, setNumberOfBedrooms] = useState(
    query.get("numberOfBedrooms") || ""
  );
  const [lengthOfLease, setLengthOfLease] = useState(
    query.get("lengthOfLease") || ""
  );
  const [roomType, setRoomType] = useState(query.get("roomType") || "");
  const [utilities, setUtilities] = useState(query.get("utilities") || "");
  const [rent, setRent] = useState(query.get("rent") || "");
  const [securityDeposit, setSecurityDeposit] = useState(
    query.get("securityDeposit") || ""
  );
  const [serviceFee, setServiceFee] = useState(query.get("serviceFee") || "");
  const [services, setServices] = useState([]);

  // useState for works
  const [serviceId, setServiceId] = useState("");
  const [viewService, setViewService] = useState("");
  const [error, setError] = useState("");
  const [interestError, setInterestError] = useState("");

  // useState for sorting
  const [showSort, setShowSort] = useState(false);
  const [searchRent, setSearchRent] = useState(query.get("rent") || "");
  const [searchSecurityDeposit, setSearchSecurityDeposit] = useState(
    query.get("securityDeposit") || ""
  );

  const service = query.get("service");

  // functions
  const logoutHandler = () => {
    dispatch(logoutUser())
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleUpdatePage = (e) => {
    setPageNumber(e);
    query.set("pageNumber", e);
    navigate(`${location.pathname}?${query.toString()}`);
  };

  const handleIds = (id) => {
    setServiceId(id);
    setInterestError("");
  };

  const handleView = (value) => {
    return setViewService(value);
  };

  const handleSubmit = (file) => {
    dispatch(
      createInterest({ serviceName: service, id: serviceId, file })
    ).then(() => {
      if (interestError) {
        return toast.error(interestError);
      } else {
        toast.success(t("findWork.Interest created successfully"));
        const updatedServices = services.filter((e) => e.id !== serviceId);
        setServices(updatedServices);
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setRent(searchRent);
    setSecurityDeposit(securityDeposit);
    query.set("rent", searchRent);
    query.set("securityDeposit", searchSecurityDeposit);

    navigate({ search: query.toString() }, { replace: true });
  };

  // useEffect
  useEffectFindWork({
    user,
    dispatch,
    OthersInDService,
    service,
    state,
    city,
    uploadDate,
    moveInDate,
    rent,
    securityDeposit,
    serviceFee,
    propertyType,
    numberOfBedrooms,
    lengthOfLease,
    roomType,
    utilities,
    pageNumber,
    handleView,
    setServices,
    othersInDService,
    setState,
    userInfo,
    interest,
    setError,
    inDService,
    setInterestError,
    handleIds,
    query,
    navigate,
    location,
  });

  const seo = seoData.FindWork;
  return (
    <>
      <SEO
        title={`Offer ${service} services - Stayforall`}
        description={`Explore ${service} requests for finding ${service} in Germany.`}
        keywords={`${service} requests, Stayforall, Germany`}
        ogTitle={`Offer ${service} services - Stayforall`}
        ogDescription={`Discover requests to help others to find ${service} in Germany.`}
        ogImage={seo.ogImage}
        ogUrl={`/myProfile/find_work?service=${service}&pageNumber=1&uploadDate=-1&state=Berlin`}
        twitterTitle={`Offer ${service} services - Stayforall`}
        twitterDescription={`Explore ${service} requests in Germany with Stayforall.`}
        twitterImage={seo.twitterImage}
        canonicalUrl={`/myProfile/find_work?service=${service}&pageNumber=1&uploadDate=-1&state=Berlin`}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          name: `Offer ${service} services - Stayforall`,
          description: `Explore ${service} requests for finding ${service} in Germany.`,
          brand: {
            "@type": "Brand",
            name: "Stayforall",
          },
        }}
      />
      {user.loading ? (
        <Loading type="servicePage" color="#36d7b7" size={120} />
      ) : (
        <>
          {/*overView head*/}
          <div className="overveiw_cont findWorkCont">
            <div className="findWork_profile mt-2">
              <div className="flex items-center cursor-pointer">
                <img
                  onClick={() => navigate("/")}
                  className="rounded-circle w-[50px] h-[50px]"
                  src={userInfo.user?.profileImage}
                  alt="user"
                />

                <div className="flex items-center text-nowrap font-bold text-[17px] ml-2">
                  {userInfo.user?.name}
                  <VerifiedStatus isVerified={userInfo.user?.isVerified} />
                </div>
              </div>
              <div className="profileNavAlign text-white">
                <span
                  onClick={() =>
                    navigate(`/inDirectServicePage/Room?status=pending`)
                  }
                  className="cursor-pointer mr-3"
                >
                  <IoAdd className="" />{" "}
                  <span className="findLarge ml-2">
                    {t("workStatus.Create")}
                  </span>
                </span>

                <ul className="flex items-center m-0 p-0 border bg-white rounded-circle">
                  <MessageNotification />
                </ul>
                <div
                  className="profileNavAlign2 homeAnchor p-2 cursor-pointer"
                  onClick={logoutHandler}
                >
                  <MdOutlineLogout className="my-2" />
                </div>
              </div>
            </div>

            <div className="bg-white">
              {/* sort options */}
              <Filtering
                t={t}
                germanStates={germanStates}
                service={service}
                stateOptions={germanStates}
                state={state}
                setState={setState}
                // Room
                city={city}
                setCity={setCity}
                uploadDate={uploadDate}
                setUploadDate={setUploadDate}
                moveInDate={moveInDate}
                setMoveInDate={setMoveInDate}
                rent={rent}
                setRent={setRent}
                securityDeposit={securityDeposit}
                setSecurityDeposit={setSecurityDeposit}
                serviceFee={serviceFee}
                setServiceFee={setServiceFee}
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                numberOfBedrooms={numberOfBedrooms}
                setNumberOfBedrooms={setNumberOfBedrooms}
                lengthOfLease={lengthOfLease}
                setLengthOfLease={setLengthOfLease}
                roomType={roomType}
                setRoomType={setRoomType}
                utilities={utilities}
                setUtilities={setUtilities}
              />
            </div>
            <Form
              className="flex justify-center items-center"
              onSubmit={handleSearch}
            >
              <div className="flex justify-center items-center rounded-[10px] pt-1 pb-2 px-3 m-2 bg-white">
                <Form.Group className="flex items-center mr-2 pl-4">
                  <img
                    className="w-[22px]"
                    src={`/images/smallicons/rent.png`}
                    alt=""
                  />
                  <Form.Control
                    type="number"
                    placeholder={t("findWork.Rent")}
                    value={searchRent}
                    onChange={(e) => setSearchRent(e.target.value)}
                    className="border-0 shadow-none"
                  />
                </Form.Group>
                <Form.Group
                  style={{ borderLeft: "1px solid grey" }}
                  className="flex items-center mr-2 pl-4"
                >
                  <img
                    className="w-[22px]"
                    src={`/images/smallicons/securityDeposit.png`}
                    alt=""
                  />
                  <Form.Control
                    type="number"
                    placeholder={t("findWork.Security Deposit")}
                    value={searchSecurityDeposit}
                    onChange={(e) => setSearchSecurityDeposit(e.target.value)}
                    className="border-0 shadow-none"
                  />
                </Form.Group>

                <Button
                  variant=""
                  type="submit"
                  style={{ background: "#0cafff" }}
                  className="text-white p-2 rounded-circle transition duration-200 shadow hover:scale-[1.05]"
                >
                  <FaSearch />
                </Button>
              </div>
            </Form>

            <div className="findSmall bg-[#f7f7f9] flex items-center justify-end">
              <button
                onClick={() => setShowSort(true)}
                // style={{ border: "1px solid rgb(148, 147, 147, .2)" }}
                className="shadow-sm bg-white border rounded-2 px-2 flex items-center mx-2 text-[19px]"
              >
                <FaFilterCircleDollar className="mr-2" />
                {t("findWork.Filter")}
              </button>
            </div>
            <Row className="pl-2 pr-5">
              {/*overveiw body*/}
              <Col md={8} lg={9} className="findWorkBody ">
                {interest.loading && (
                  <Loading type="hoverLoading" color="#36d7b7" size={120} />
                )}
                {/* error */}
                {error && (
                  <Alert variant="danger" className="col-span-2 mt-3">
                    {error}
                  </Alert>
                )}
                {/*content start*/}
                {othersInDService.loading ? (
                  <div className="heightFindWork">
                    <Loading type="colOne" color="#36d7b7" size={20} />
                  </div>
                ) : othersInDService.services?.length == 0 ? (
                  <div className="text-center pt-[20px] pb-[60vh]">
                    <h6 className="flex flex-col font-bold font-[Helvetica] items-center rounded text-secondary bg-white shadow-md p-5">
                      <img
                        src="/images/smallicons/completeWarning.png"
                        alt=""
                        className="w-14 mb-3"
                      />{" "}
                      {t("findWork.No")} {t(service).toLowerCase()}{" "}
                      {t("findWork.requests available. Check back soon")}
                    </h6>
                  </div>
                ) : (
                  <div className="min-h-[80vh] flex justify-center w-[100%]">
                    <Room
                      t={t}
                      services={services}
                      viewService={viewService}
                      serviceId={serviceId}
                      handleView={handleView}
                      handleIds={handleIds}
                      handleSubmit={handleSubmit}
                      interestError={interestError}
                    />
                  </div>
                )}
                {/*content end*/}
              </Col>

              <Col className="findLarge border bg-white shadow-md rounded px-2 py-3 my-3">
                <h3 className="font-bold text-[19px]">
                  {t("findWork.Filter")} {t("findWork.services")}
                </h3>
                <p className="text-secondary text-[15px]">
                  {t("findWork.showing")}{" "}
                  {pageNumber == 1
                    ? `1-12`
                    : `${1 * pageNumber}-${12 * pageNumber}`}{" "}
                  {t("findWork.services")}
                </p>

                <Filtering
                  t={t}
                  germanStates={germanStates}
                  service={service}
                  stateOptions={germanStates}
                  state={state}
                  setState={setState}
                  // Room
                  city={city}
                  setCity={setCity}
                  uploadDate={uploadDate}
                  setUploadDate={setUploadDate}
                  moveInDate={moveInDate}
                  setMoveInDate={setMoveInDate}
                  rent={rent}
                  setRent={setRent}
                  securityDeposit={securityDeposit}
                  setSecurityDeposit={setSecurityDeposit}
                  serviceFee={serviceFee}
                  setServiceFee={setServiceFee}
                  propertyType={propertyType}
                  setPropertyType={setPropertyType}
                  numberOfBedrooms={numberOfBedrooms}
                  setNumberOfBedrooms={setNumberOfBedrooms}
                  lengthOfLease={lengthOfLease}
                  setLengthOfLease={setLengthOfLease}
                  roomType={roomType}
                  setRoomType={setRoomType}
                  utilities={utilities}
                  setUtilities={setUtilities}
                  showSort={showSort}
                  setShowSort={setShowSort}
                  forLarge={true}
                />
              </Col>
            </Row>
            {/*end section*/}
            <div className="pageNavigate">
              {pageNumber != "1" && (
                <button
                  onClick={() => handleUpdatePage(parseInt(pageNumber) - 1)}
                  className="cursor-pointer rounded-[25px] bg-white text-secondary px-2 py-1 shadow-md flex items-center"
                >
                  <GrLinkPrevious className="mr-2" />
                  <span className="font-bold">{t("findWork.Previous")}</span>
                </button>
              )}
              <div className="text-[14px] text-grey mx-5">
                {t("findWork.Page")} {pageNumber}
              </div>
              {services?.length > 21 && (
                <button
                  onClick={() => handleUpdatePage(parseInt(pageNumber) + 1)}
                  className="cursor-pointer rounded-[25px] bg-white text-secondary px-2 py-1 shadow-md flex items-center"
                >
                  <span className="font-bold">{t("findWork.Next")}</span>
                  <GrLinkNext className="ml-2" />
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
