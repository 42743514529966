import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Card,
  Form,
  Spinner,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DOMPurify from "dompurify";

const BlogPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [editingBlog, setEditingBlog] = useState(null);
  const [page, setPage] = useState(Number(query.get("page")) || 1);
  const [image, setImage] = useState("");
  const [caption, setCaption] = useState("");
  const [structuredData, setStructuredData] = useState("");
  const [fullDetails, setFullDetails] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");

  useEffect(() => {
    fetchBlogs();
  }, [page]);

  useEffect(() => {
    if (editingBlog) {
      setImage(editingBlog.image || "");
      setCaption(editingBlog.caption || "");
      setStructuredData(editingBlog.structuredData || "");
      setFullDetails(editingBlog.fullDetails || "");
      setFaqs(editingBlog.faqs || []);
    } else {
      setImage("");
      setCaption("");
      setStructuredData("");
      setFullDetails("");
      setFaqs([]);
    }
  }, [editingBlog]);

  const handlePageChange = (newPage) => {
    if (newPage < 1) return;
    query.set("page", newPage);
    navigate(`${window.location.pathname}?${query.toString()}`);
    setPage(newPage);
  };

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/blogsAndArticles/${page}`);
      setBlogs(response.data.blogsAndArticles);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateBlog = async (e) => {
    e.preventDefault();
    try {
      const blogData = { image, caption, fullDetails, faqs };
      if (editingBlog) {
        await axios.put(
          `${apiUrl}/blogsAndArticles/${editingBlog._id}`,
          blogData
        );
      } else {
        await axios.post(`${apiUrl}/blogsAndArticles`, blogData);
      }
      setEditingBlog(null);
      fetchBlogs();
    } catch (error) {
      console.error("Error submitting blog:", error);
    }
  };

  const handleNewBlog = () => {
    setEditingBlog(null);
    setFaqs([]);
  };

  const handleEditBlog = (blog) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setEditingBlog(blog);
  };

  const submit = (title, message, label1, label2, clickHandler) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [{ label: label1, onClick: clickHandler }, { label: label2 }],
    });
  };

  const handleDeleteBlog = async (id) => {
    try {
      await axios.delete(`${apiUrl}/blogsAndArticles/${id}`);
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const handleAddFaq = () => {
    if (faqQuestion && faqAnswer) {
      setFaqs([...faqs, { question: faqQuestion, answer: faqAnswer }]);
      setFaqQuestion("");
      setFaqAnswer("");
    } else {
      alert("Please fill out both question and answer.");
    }
  };

  const handleRemoveFaq = (index) => {
    const updatedFaqs = faqs.filter((_, idx) => idx !== index);
    setFaqs(updatedFaqs);
  };
  return (
    <Container className="my-4">
      <h1 className="text-center mb-4">Blogs and Articles</h1>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {/* Blog Form */}
          <Form
            onSubmit={handleCreateOrUpdateBlog}
            className="bg-white p-4 rounded shadow-sm mb-5"
          >
            <h4>{editingBlog ? "Edit Blog" : "Create New Blog"}</h4>
            <Form.Group controlId="formImage" className="mb-3">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image URL"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCaption" className="mb-3">
              <Form.Label>Caption</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formFullDetails" className="mb-3">
              <Form.Label>Full Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter full details"
                value={fullDetails}
                onChange={(e) => setFullDetails(e.target.value)}
                required
              />
            </Form.Group>

            {/* FAQ Inputs */}
            <div className="border rounded p-2">
              <h5>FAQs</h5>
              <Form.Group controlId="faqQuestion" className="mb-2">
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter FAQ question"
                  value={faqQuestion}
                  onChange={(e) => setFaqQuestion(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="faqAnswer" className="mb-2">
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter FAQ answer"
                  value={faqAnswer}
                  onChange={(e) => setFaqAnswer(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="secondary"
                type="button"
                onClick={handleAddFaq}
                className="mb-3"
              >
                Add FAQ
              </Button>
            </div>

            {/* Display Added FAQs */}
            {faqs.length > 0 && (
              <div>
                <h6>Added FAQs:</h6>
                <ul>
                  {faqs.map((faq, index) => (
                    <li key={index}>
                      <strong>Q:</strong> {faq.question} <br />
                      <strong>A:</strong> {faq.answer}
                      <Button
                        variant="danger"
                        size="sm"
                        className="ms-2"
                        onClick={() => handleRemoveFaq(index)}
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <Button variant="primary" type="submit" className="me-2">
              {editingBlog ? "Update Blog" : "Create Blog"}
            </Button>
            <Button variant="success" onClick={handleNewBlog} className="mt-2">
              Create New Blog
            </Button>
          </Form>

          {/* Blog List */}
          <Row xs={1} md={2} lg={3} className="g-4">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <Col key={blog._id}>
                  <Card className="h-100 shadow-sm">
                    <Card.Img
                      variant="top"
                      src={blog.image}
                      alt="Blog Image"
                      loading="lazy"
                    />
                    <Card.Body>
                      <Card.Title>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(blog.caption),
                          }}
                        />{" "}
                      </Card.Title>
                      <Button
                        variant="warning"
                        onClick={() => handleEditBlog(blog)}
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() =>
                          submit(
                            "Delete Blog",
                            "Are you sure you want to delete this blog?",
                            "Yes",
                            "No",
                            () => handleDeleteBlog(blog._id)
                          )
                        }
                      >
                        Delete
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">No blogs available.</p>
            )}
          </Row>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-center my-4">
            <Button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="me-2"
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(page + 1)}
              disabled={blogs.length < 12}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default BlogPage;
