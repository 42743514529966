import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { GiButterfly } from "react-icons/gi";
import FooterTop from "../../../components/common/FooterTop";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../../../components/Navigation/Navigation";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllBlogsAndArticles } from "../../../store/Actions/BlogsAndArticle";
import Loading from "../../../components/samplePage/Loading";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import "../Social.scss";
import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";

export default function BlogScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const blogsAndArticle = useSelector((state) => state.blogsAndArticle);
  const query = new URLSearchParams(location.search);
  const [blogs, setBlogs] = useState([]);
  const page = query.get("page") || 1;

  const handleUpdatePage = (newPage) => {
    navigate(`${location.pathname}?page=${newPage}`);
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    dispatch(getAllBlogsAndArticles(page));
  }, [page, dispatch]);

  useEffect(() => {
    setBlogs(blogsAndArticle.blogsAndArticle);
  }, [blogsAndArticle]);

  const seo = seoData.BlogScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <Navigation />
      <div className="py-10">
        <div className="text-center my-5">
          <h1 className="flex items-center justify-center my-2">
            {t("infoPage.Writings from our team")}
            <GiButterfly className="ml-2" />
          </h1>
          <h6>{t("infoPage.The latest details for students and workers")}</h6>
        </div>

        {blogsAndArticle.loading ? (
          <Loading type="servicePage" color="#36d7b7" size={120} />
        ) : (
          <Container className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {blogs?.length > 0 ? (
              blogs.map((blog) => (
                <Card
                  key={blog?._id}
                  onClick={() =>
                    navigate(
                      `/blogs&article-Stayforall/${encodeURIComponent(
                        blog?._id
                      )}`
                    )
                  }
                  className="shadow-md hover:scale-105 transition-transform duration-300 cursor-pointer"
                  aria-label={`Read blog: ${blog?.caption || "Blog"}`}
                >
                  <div className="w-full h-48 overflow-hidden">
                    <Card.Img
                      variant="top"
                      src={blog?.image}
                      alt={blog?.caption || "Blog Image"}
                      loading="lazy"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(blog?.caption || ""),
                        }}
                      />
                    </Card.Title>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>No blogs available at the moment.</p>
            )}
          </Container>
        )}

        <div className="mt-6 mb-6 flex justify-center items-center bg-gradient-to-r from-[#e4f7ff] to-[#eaf8ff] p-4 rounded-lg shadow-lg">
          {/* Previous Button */}
          <button
            onClick={() => handleUpdatePage(Math.max(1, Number(page) - 1))}
            disabled={page === "1"}
            aria-label={t("infoPage.Previous")}
            className={`flex items-center gap-2 px-2 py-2 rounded-circle shadow-md transition-all duration-300 ${
              page === "1"
                ? "cursor-not-allowed bg-[#e0e0e0] text-[#bdbdbd]"
                : "cursor-pointer bg-[#1d73b1] text-white hover:bg-[#146a98] hover:scale-105"
            }`}
          >
            <GrLinkPrevious className="text-xl" />
            {/* <span className="font-semibold">{t("infoPage.Previous")}</span> */}
          </button>

          {/* Current Page Indicator */}
          <div className="text-lg font-bold text-[#2a4377] mx-3">
            {`${t("infoPage.Page")} ${page}`}
          </div>

          {/* Next Button */}
          <button
            onClick={() => handleUpdatePage(Number(page) + 1)}
            disabled={blogs?.length < 12}
            aria-label={t("infoPage.Next")}
            className={`flex items-center gap-2 px-2 py-2 rounded-circle shadow-md transition-all duration-300 ${
              blogs?.length < 12
                ? "cursor-not-allowed bg-[#e0e0e0] text-[#bdbdbd]"
                : "cursor-pointer bg-[#1d73b1] text-white hover:bg-[#146a98] hover:scale-105"
            }`}
          >
            {/* <span className="font-semibold">{t("infoPage.Next")}</span> */}
            <GrLinkNext className="text-xl" />
          </button>
        </div>
      </div>
      <FooterTop bottom="bottom" />
    </>
  );
}
