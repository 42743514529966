import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Card,
  Form,
  Spinner,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DOMPurify from "dompurify";

const NewsPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [editingNews, setEditingNews] = useState(null);
  const [page, setPage] = useState(Number(query.get("page")) || 1);
  const [image, setImage] = useState("");
  const [caption, setCaption] = useState("");
  const [structuredData, setStructuredData] = useState("");
  const [fullDetails, setFullDetails] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");

  useEffect(() => {
    fetchNews();
  }, [page]);

  useEffect(() => {
    if (editingNews) {
      setImage(editingNews.image || "");
      setCaption(editingNews.caption || "");
      setStructuredData(editingNews.structuredData || "");
      setFullDetails(editingNews.fullDetails || "");
      setFaqs(editingNews.faqs || []);
    } else {
      setImage("");
      setCaption("");
      setStructuredData("");
      setFullDetails("");
      setFaqs([]);
    }
  }, [editingNews]);

  const handlePageChange = (newPage) => {
    if (newPage < 1) return;
    query.set("page", newPage);
    navigate(`${window.location.pathname}?${query.toString()}`);
    setPage(newPage);
  };

  const fetchNews = async () => {
    // Changed from fetchBlogs to fetchNews
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/news/${page}`);
      setNews(response.data.allNews); // Changed blogs to news
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateNews = async (e) => {
    e.preventDefault();
    try {
      const newsData = { image, caption, fullDetails, faqs };
      if (editingNews) {
        await axios.put(`${apiUrl}/news/${editingNews._id}`, newsData);
      } else {
        await axios.post(`${apiUrl}/news`, newsData);
      }
      setEditingNews(null);
      fetchNews();
    } catch (error) {
      console.error("Error submitting news:", error);
    }
  };

  const handleNewNews = () => {
    setEditingNews(null);
    setFaqs([]);
  };

  const handleEditNews = (newsItem) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setEditingNews(newsItem);
  };

  const submit = (title, message, label1, label2, clickHandler) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [{ label: label1, onClick: clickHandler }, { label: label2 }],
    });
  };

  const handleDeleteNews = async (id) => {
    try {
      await axios.delete(`${apiUrl}/news/${id}`);
      fetchNews();
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  const handleAddFaq = () => {
    if (faqQuestion && faqAnswer) {
      setFaqs([...faqs, { question: faqQuestion, answer: faqAnswer }]);
      setFaqQuestion("");
      setFaqAnswer("");
    } else {
      alert("Please fill out both question and answer.");
    }
  };

  const handleRemoveFaq = (index) => {
    const updatedFaqs = faqs.filter((_, idx) => idx !== index);
    setFaqs(updatedFaqs);
  };

  return (
    <Container className="my-4">
      <h1 className="text-center mb-4">News</h1>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {/* News Form */}
          <Form
            onSubmit={handleCreateOrUpdateNews}
            className="bg-white p-4 rounded shadow-sm mb-5"
          >
            <h4>{editingNews ? "Edit News" : "Create New News"}</h4>
            <Form.Group controlId="formImage" className="mb-3">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image URL"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCaption" className="mb-3">
              <Form.Label>Caption</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formFullDetails" className="mb-3">
              <Form.Label>Full Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter full details"
                value={fullDetails}
                onChange={(e) => setFullDetails(e.target.value)}
                required
              />
            </Form.Group>

            {/* FAQ Inputs */}
            <div className="border rounded p-2">
              <h5>FAQs</h5>
              <Form.Group controlId="faqQuestion" className="mb-2">
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter FAQ question"
                  value={faqQuestion}
                  onChange={(e) => setFaqQuestion(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="faqAnswer" className="mb-2">
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter FAQ answer"
                  value={faqAnswer}
                  onChange={(e) => setFaqAnswer(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="secondary"
                type="button"
                onClick={handleAddFaq}
                className="mb-3"
              >
                Add FAQ
              </Button>
            </div>

            {/* Display Added FAQs */}
            {faqs.length > 0 && (
              <div>
                <h6>Added FAQs:</h6>
                <ul>
                  {faqs.map((faq, index) => (
                    <li key={index}>
                      <strong>Q:</strong> {faq.question} <br />
                      <strong>A:</strong> {faq.answer}
                      <Button
                        variant="danger"
                        size="sm"
                        className="ms-2"
                        onClick={() => handleRemoveFaq(index)}
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <Button variant="primary" type="submit" className="me-2">
              {editingNews ? "Update News" : "Create News"}
            </Button>
            <Button variant="success" onClick={handleNewNews} className="mt-2">
              Create New News
            </Button>
          </Form>

          {/* News List */}
          <Row xs={1} md={2} lg={3} className="g-4">
            {news.length > 0 ? (
              news.map((newsItem) => (
                <Col key={newsItem._id}>
                  <Card className="h-100 shadow-sm">
                    <Card.Img
                      variant="top"
                      src={newsItem.image}
                      alt="News Image"
                      loading="lazy"
                    />
                    <Card.Body>
                      <Card.Title>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(newsItem.caption),
                          }}
                        />
                      </Card.Title>
                      <Button
                        variant="warning"
                        onClick={() => handleEditNews(newsItem)}
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() =>
                          submit(
                            "Delete News",
                            "Are you sure you want to delete this News?",
                            "Yes",
                            "No",
                            () => handleDeleteNews(newsItem._id)
                          )
                        }
                      >
                        Delete
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">No News available.</p>
            )}
          </Row>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-center my-4">
            <Button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="me-2"
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(page + 1)}
              disabled={news.length < 12}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default NewsPage;
