import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function StepsOrder({ step, stringStep, type }) {
  if (stringStep === "pending" || stringStep === "success") {
    return null;
  }

  return (
    <Container className="steporder">
      <Row className="checkout-steps">
        <Col className={step >= 1 ? "active" : ""}>{type[0]}</Col>
        <Col className={step >= 2 ? "active" : ""}>{type[1]}</Col>
        <Col className={step >= 3 ? "active" : ""}>{type[2]}</Col>
        {type[3] && <Col className={step === 4 ? "active" : ""}>{type[3]}</Col>}
      </Row>

      <Row className="checkout-spheres">
        <Col className={step >= 1 ? "active" : ""}>
          <div className=""></div>
        </Col>
        <Col className={step >= 2 ? "active" : ""}>
          <div className=""></div>
        </Col>
        <Col className={step >= 3 ? "active" : ""}>
          <div className=""></div>
        </Col>
        {type[3] && (
          <Col className={step === 4 ? "active" : ""}>
            <div className=""></div>
          </Col>
        )}
      </Row>
    </Container>
  );
}
