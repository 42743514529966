import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { apiUrl } from "../../../utils/apiUrl";
import DOMPurify from "dompurify";
import { FaWhatsapp } from "react-icons/fa";
import { FaLink } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function SocialMediaRooms({ t, state, city, rent }) {
  const navigate = useNavigate();

  const [rooms, setRooms] = useState([]); // To store the fetched data
  const [error, setError] = useState(null); // To handle errors
  const [loading, setLoading] = useState(false); // To show a loading state
  const [page, setPage] = useState(1); // To track the current page
  const [hasMore, setHasMore] = useState(true); // To track if there are more rooms to load
  const scrollPosition = useRef(0);
  const containerRef = useRef(null);

  const fetchRooms = async () => {
    setLoading(true); // Start loading
    setError(null); // Clear previous errors
    try {
      const response = await axios.get(
        `${apiUrl}/room_listing/social_media_rooms/${state}/${city}/${rent}/${page}`,
        { withCredentials: true }
      );
      const newRooms = response.data?.data || [];
      setRooms((prevRooms) => [...prevRooms, ...newRooms]); // Append new rooms to the existing ones
      if (newRooms.length === 0) {
        setHasMore(false); // No more rooms to load
      }
    } catch (err) {
      console.error("Error fetching rooms:", err); // Log the error
      setError(
        err.response?.data?.message || "No rooms found matching the criteria"
      ); // Set error state
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Load rooms when component mounts or when parameters change
  useEffect(() => {
    if (state && city && rent) {
      setRooms([]); // Clear rooms on parameter change
      setHasMore(true); // Reset hasMore when the parameters change
      setPage(1); // Reset to the first page
      fetchRooms();
    }
  }, [state, city, rent]);

  // Detect when user scrolls to the bottom and load more rooms
  const handleScroll = (e) => {
    const container = e.target;
    const bottom =
      container.scrollHeight - container.scrollTop - container.clientHeight < 1;

    if (bottom && hasMore && !loading) {
      // Save the current scroll position before the page changes
      scrollPosition.current = container.scrollTop;
      setPage((prevPage) => prevPage + 1); // Increment the page number
    }
  };

  useEffect(() => {
    if (containerRef.current && scrollPosition.current !== 0) {
      containerRef.current.scrollTop = scrollPosition.current;
    }
  }, [rooms]);

  useEffect(() => {
    if (page > 1) {
      fetchRooms(); // Fetch more rooms when the page changes
    }
  }, [page]);

  const buttonClasses = "border p-2 rounded flex items-center justify-center";

  return (
    <div className="border rounded p-0 bg-[grey]">
      <h2 className="bg-[#238de4] p-2 rounded-t">
        {t("inDirectServicePage.Leads from Social Network")}
        <p className="text-sm mt-1 text-[#ffffffdc]">
          {t("inDirectServicePage.The data is sourced from social media")}{" "}
          <span className="font-bold text-blue-500">
            {t("inDirectServicePage.verification service")}
          </span>{" "}
          {t("inDirectServicePage.to validate the room")}.
        </p>
      </h2>
      <div
        onScroll={handleScroll}
        ref={containerRef}
        className="max-h-[70vh] overflow-auto"
      >
        {error && (
          <div
            className="text-center mt-5 p-2 error-message"
            style={{ color: "red" }}
          >
            {error}
            <button
              onClick={fetchRooms}
              className="ml-2 bg-red-500 text-white px-3 py-1 rounded"
            >
              {t("inDirectServicePage.Retry")}
            </button>
          </div>
        )}

        {!loading && !error && rooms.length === 0 && (
          <div className="text-center mt-5">
            <p>
              {t("inDirectServicePage.No rooms found matching your criteria")}.
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded mt-3"
              onClick={fetchRooms}
            >
              {t("inDirectServicePage.Try Again")}
            </button>
          </div>
        )}

        {rooms.length > 0 &&
          rooms.map((room, index) => (
            <div key={index} className="bg-[#ffffff93] border rounded m-2 p-2">
              <div className="grid grid-cols-3 gap-3">
                {room.number.length > 8 && (
                  <a
                    href={`https://wa.me/${room.number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${buttonClasses} bg-[#075E54] text-white`}
                  >
                    <FaWhatsapp className="mr-2" />{" "}
                    {t("inDirectServicePage.Connect")}
                  </a>
                )}
                {room.link && (
                  <a
                    href={room.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${buttonClasses} bg-[#075E54] text-white`}
                  >
                    <FaLink className="mr-2" />{" "}
                    {t("inDirectServicePage.Connect")}
                  </a>
                )}

                <button
                  onClick={() => {
                    navigate(
                      `/directServicePage/Room%20verification?step=form`
                    );
                  }}
                  className="border p-2 rounded shadow-sm hover:bg-[grey]"
                >
                  {t("inDirectServicePage.Submit for verification")}
                </button>
              </div>
              <div
                className="mt-4"
                style={{ fontSize: "14px" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    room.detail || t("inDirectServicePage.No details available")
                  ),
                }}
              />
            </div>
          ))}

        {loading && (
          <div className="flex justify-center items-center mt-5">
            <div className="loader spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" />
          </div>
        )}
      </div>
    </div>
  );
}
